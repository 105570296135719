import { Fields } from 'components/organisms/form/createProcess/types';

import { ProcessVersionGeneral } from 'api/endpoints/processVersions';
import { TenantGeneral } from 'api/endpoints/tenants';
import {
  WithReleaseOrDraftProcessId,
  WithTenantId,
} from 'api/types/pathParams';

import ApiUrlParams from 'constants/apiUrlParams';

import { excludeObjectProperty } from 'utils/normalizers/object';

import { API_REQ_ACTION_TYPES } from '../actions';
import apiReqSagaCreator from '../apiReqSagaCreator';
import {
  ApiPaths,
  CONTENT_TYPE,
  HTTP_METHODS,
  RESPONSE_TYPE,
} from '../constants';

export interface CreateProcessParams {
  name: string;
  tenantId: string;
  description?: string;
}

export interface ProcessItemWithData<T> {
  data: T;
}

export type ProcessState = 'draft' | 'released';

export type ProcessStatus = 'active' | 'inactive';

export interface ProcessGeneral extends BaseEntity, WithHypermedia {
  description?: string;
  state: ProcessState;
  status: ProcessStatus;
  version?: ProcessVersionGeneral;
  versions?: ProcessVersionGeneral[];
}

export interface ProcessDetails extends ProcessGeneral {
  tenant: TenantGeneral;
  urls?: {
    printRender: string;
    ftpPolling: string;
    ftpError: string;
  };
}

export interface ProcessConfig extends BaseEntity, WithHypermedia {
  securityGroupsEnabled: boolean;
}

export type GetProcessesParams = Pagination & {
  processStatus?: ProcessStatus;
  state?: ProcessState;
  versionStatus?: ProcessStatus;
};

export type GetProcessesResult = ProcessGeneral;

export interface SendFilesViaEmail {
  // see also: src/components/organisms/form/sendFiles/types.ts@Fields
  data: {
    to: string;
    cc?: string[];
    bcc?: string[];
    body?: string;
    attachmentName: string;
    subject?: string;
    replyTo: string;
    fields: KeyValuePairs<string[]>;
  };
}

export type GetByIdParams = WithReleaseOrDraftProcessId;
export type GetByIdResult = ProcessDetails;
export type CreateProcessResult = ProcessGeneral;
export type UpdateProcessParams = ProcessItemWithData<
  Omit<Fields, 'tenant'> & WithTenantId
> &
  WithReleaseOrDraftProcessId;
export type UpdateProcessResult = ProcessGeneral;
export type DeleteProcessParams = WithReleaseOrDraftProcessId;
export type DeleteProcessResult = unknown;
export type DeactivateProcessParams = WithReleaseOrDraftProcessId;
export type DeactivateProcessResult = unknown;
export type ActivateProcessParams = WithReleaseOrDraftProcessId;
export type ActivateProcessResult = unknown;
export type ExportProcessParams = WithReleaseOrDraftProcessId;
export type ExportProcessResult = Blob;
export type ImportProcessParams = { file: FormData } & WithTenantId;
export type ImportProcessResult = ProcessGeneral;
export type SendFilesViaEmailParams = SendFilesViaEmail &
  WithReleaseOrDraftProcessId;
export type SendFilesViaEmailResult = unknown;

const Processes = {
  getAll: apiReqSagaCreator<GetProcessesParams, GetProcessesResult>(
    API_REQ_ACTION_TYPES.PROCESSES.GET_ALL,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes._(),
      params,
    })
  ),
  getById: apiReqSagaCreator<GetByIdParams, GetByIdResult>(
    API_REQ_ACTION_TYPES.PROCESSES.GET_BY_ID,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId._(params),
    })
  ),
  create: apiReqSagaCreator<CreateProcessParams, CreateProcessResult>(
    API_REQ_ACTION_TYPES.PROCESSES.CREATE,
    (data) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.processes._(),
      data,
    })
  ),
  updateProcess: apiReqSagaCreator<UpdateProcessParams, UpdateProcessResult>(
    API_REQ_ACTION_TYPES.PROCESSES.UPDATE_BY_ID,
    (args) => ({
      method: HTTP_METHODS.PUT,
      url: ApiPaths.processes.byId._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
      }),
      data: args.data,
    })
  ),
  deleteProcess: apiReqSagaCreator<DeleteProcessParams, DeleteProcessResult>(
    API_REQ_ACTION_TYPES.PROCESSES.DELETE_BY_ID,
    (params) => ({
      method: HTTP_METHODS.DELETE,
      url: ApiPaths.processes.byId._(params),
    })
  ),
  deactivateProcess: apiReqSagaCreator<
    DeactivateProcessParams,
    DeactivateProcessResult
  >(API_REQ_ACTION_TYPES.PROCESSES.DEACTIVATE, (params) => ({
    method: HTTP_METHODS.POST,
    url: ApiPaths.processes.byId.deactivate._(params),
  })),
  activateProcess: apiReqSagaCreator<
    ActivateProcessParams,
    ActivateProcessResult
  >(API_REQ_ACTION_TYPES.PROCESSES.ACTIVATE, (params) => ({
    method: HTTP_METHODS.PUT,
    url: ApiPaths.processes.byId.activate(params),
  })),
  exportProcess: apiReqSagaCreator<ExportProcessParams, ExportProcessResult>(
    API_REQ_ACTION_TYPES.PROCESSES.EXPORT,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId.export._(params),
      responseType: RESPONSE_TYPE.BLOB,
      transformResponse: (data) => data,
    })
  ),
  importProcess: apiReqSagaCreator<ImportProcessParams, ImportProcessResult>(
    API_REQ_ACTION_TYPES.PROCESSES.IMPORT,
    (args) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.processes.import.byTenantId._({
        [ApiUrlParams.tenantId]: args[ApiUrlParams.tenantId],
      }),
      data: excludeObjectProperty(args, ApiUrlParams.tenantId).file,
      headers: {
        'Content-Type': CONTENT_TYPE.MULTIPART_FORMDATA,
      },
    })
  ),
  sendFilesViaEmail: apiReqSagaCreator<
    SendFilesViaEmailParams,
    SendFilesViaEmailResult
  >(API_REQ_ACTION_TYPES.INSTANCE.SEND_FILES_VIA_EMAIL, (args) => ({
    method: HTTP_METHODS.POST,
    url: ApiPaths.processes.byId.sendEmail._({
      [ApiUrlParams.releaseOrDraftProcessId]:
        args[ApiUrlParams.releaseOrDraftProcessId],
    }),
    data: args.data,
  })),
};

export default Processes;
