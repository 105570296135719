import type { AxiosResponse, Method } from 'axios';
import { ApiError, axiosResponseToApiResponse } from 'container/models';
import { useApplicationErrorHandling } from 'hooks/useApplicationErrorHandling';
import { SetStateAction, useCallback, useMemo, useState } from 'react';
import useSWR from 'swr';
import { BareFetcher, PublicConfiguration } from 'swr/_internal';
import useSWRMutation, { SWRMutationConfiguration } from 'swr/mutation';
import { ApplicationError } from 'types/errorHandling';

import ApiUrlParams from 'constants/apiUrlParams';

import { authHeaderName } from './apiReqSagaCreator/config';
import {
  handleExpiredTokens,
  httpClient,
  transformerConfigFactory,
} from './apiReqSagaCreator/httpRequest';
import { authCache } from './cache';
import { ApiPaths } from './constants';
import { ProcessDetails } from './endpoints/processes';
import { User } from './endpoints/users';

type RealizedKey = string | null | undefined;
type Key = RealizedKey | (() => RealizedKey);

type Hydrator<Data> = (bareResponse: Data) => Data;

const responseHandler =
  <Data>(hydrator: Hydrator<Data> | undefined) =>
  (response: Data) =>
    hydrator ? hydrator(response) : response;

const getCommonHeaders = () => ({
  ...(authCache.loggedIn ? { [authHeaderName]: authCache.authToken } : {}),
});

export type UseAPIResult<Data> = {
  /** The result of the most recent successful fetch or mutation. */
  data: Data | undefined;

  /** If the most recent fetch or mutation failed, its error. */
  error: ApiError | undefined;
};

export type UseReadAPIResult<Data> = UseAPIResult<Data> & {
  /** Cause the hook to refetch its data, optionally providing data to use in the meantime. */
  mutate: (
    newData?: Data | Promise<Data | undefined>
  ) => Promise<Data | undefined>;

  /** Is the hook loading data for the first time? */
  isLoading: boolean;

  /** Is the hook loading data? */
  isValidating: boolean;
};

/**
 * A hook for fetching data from the API. Wraps useSWR to provide request authentication and error handling.
 */
export const useReadAPI = <Data>(
  /** API resource to fetch. Rooted at the API base URL, so use a leading slash, as in `/users/info`. */
  resource: Key,

  opts?: {
    /** A function that takes the raw response object (composed of primitives) and reconstructs more advanced types from it. This should be a stable reference to a function defined outside a React hook or component, or inside `useCallback`. */
    hydrator?: Hydrator<Data>;

    /** Indicate that the response for the given key will never change. If true, this will disable automatic revalidations. */
    immutable?: boolean;

    /** How long the cache is considered 'fresh' for in milliseconds. After this, the next invocation of the hook will refresh the cache. */
    lifespan?: number;

    /** A list of keys for the snake-case to camelCase mapper to ignore. */
    responseTransformerStopPaths?: string[];
  }
): UseReadAPIResult<Data> => {
  const handleApplicationError = useApplicationErrorHandling();

  // define a fetcher based on the client repo; handle application errors by wrapping them in a rejected promise
  const fetcher = useCallback(
    (key: string) =>
      axiosResponseToApiResponse(
        handleExpiredTokens(() =>
          httpClient.get<Data>(key, {
            ...transformerConfigFactory({
              responseStopPaths: opts?.responseTransformerStopPaths,
            }),
            headers: {
              ...getCommonHeaders(),
            },
          })
        )
      ).then(responseHandler(opts?.hydrator)),

    [opts?.hydrator, opts?.responseTransformerStopPaths]
  );

  // define config that provides error handling by default
  const config = useMemo(
    (): Partial<PublicConfiguration<Data, ApiError, BareFetcher<Data>>> => ({
      onErrorRetry(err, key, config, revalidate, revalidateOpts) {
        const responseClass = Math.floor(err.status / 100);

        // any other bad-request errors we don't retry
        if (responseClass === 4) {
          handleApplicationError(
            ApplicationError.FromError(err, `Failed to fetch ${key}`)
          );
          return;
        }

        // all other errors we retry with an exponential backoff
        setTimeout(
          () => revalidate(revalidateOpts),
          100 * Math.pow(2, revalidateOpts.retryCount) // exponential backoff
        );
      },

      revalidateIfStale: opts?.immutable ? false : true,
      revalidateOnFocus: opts?.immutable ? false : true,
      revalidateOnReconnect: opts?.immutable ? false : true,

      dedupingInterval: opts?.lifespan,
      focusThrottleInterval: opts?.lifespan,
    }),
    [handleApplicationError, opts?.immutable, opts?.lifespan]
  );

  return useSWR<Data, ApiError>(resource, fetcher, config);
};

/**
 * A hook for making mutation requests to the API. Wraps useSWRMutation to provide request authentication and error handling.
 *
 * @todo Construct a local return type, similar to {@link UseReadAPIResult}, that doesn't conflict with the SWRMutationResponse type. This is hard because of the typing of `trigger()`.
 */
export const useWriteAPI = <Payload, Data>(
  /** HTTP verb to use. If you want to GET, use {@link useReadAPI}. */
  method: Extract<Method, 'PUT' | 'POST' | 'PATCH' | 'DELETE'>,

  /** API resource to fetch. Rooted at the API base URL, so use a leading slash, as in `/users/info`. */
  resource: Key,

  /** Additional options. */
  opts?: {
    /** Function to rehydrate the response. Use this for advanced deserialization, e.g. for converting datestrings into Date() objects. */
    responseHydrator?: Hydrator<Data>;

    /** Function to call when a request succeeds. One use for this is for calling a {@link useReadAPI} `mutate` function to tell it to update. */
    onSuccess?: (data: Data) => void;

    /** A list of keys for the snake-case to camelCase mapper to ignore when transforming the **request** object. */
    requestTransformerStopPaths?: string[];

    /** A list of keys for the snake-case to camelCase mapper to ignore when transforming the **response** object. */
    responseTransformerStopPaths?: string[];
  }
) => {
  const handleApplicationError = useApplicationErrorHandling();

  const fetcher = useCallback(
    (resource: string, { arg }: { arg: Payload }) =>
      axiosResponseToApiResponse(
        handleExpiredTokens(() =>
          httpClient.request<Data, AxiosResponse<Data>, Payload>({
            url: resource,
            method,
            data: method !== 'DELETE' ? arg : undefined,
            ...transformerConfigFactory({
              requestStopPaths: opts?.requestTransformerStopPaths,
              responseStopPaths: opts?.responseTransformerStopPaths,
            }),
            headers: {
              ...getCommonHeaders(),
            },
          })
        )
      ).then(responseHandler(opts?.responseHydrator)),
    [
      method,
      opts?.requestTransformerStopPaths,
      opts?.responseHydrator,
      opts?.responseTransformerStopPaths,
    ]
  );

  const config = useMemo(
    (): Partial<SWRMutationConfiguration<Data, ApiError, Key, Payload>> => ({
      onError(error, key) {
        handleApplicationError(
          ApplicationError.FromError(error, `Failed to write ${key}`)
        );
      },
      onSuccess(data) {
        opts?.onSuccess?.(data);
      },
    }),
    [handleApplicationError, opts]
  );

  return useSWRMutation<Data, ApiError, Key, Payload>(
    resource,
    fetcher,
    config
  );
};

/**
 * A hook for making local changes to remote state.
 */
export function useServerAwareState<T>(serverState?: T) {
  const [isDirty, _setIsDirty] = useState(false);
  const [_clientState, _setClientState] = useState<T | undefined>(undefined);

  const clientState = useMemo(
    () => (isDirty ? _clientState : serverState),
    [_clientState, isDirty, serverState]
  );

  const setClientState = useCallback(
    (value: SetStateAction<T | undefined>) => {
      if (!isDirty) _setClientState(serverState); // if the local state is clean, update it to the server state so the next line can function properly if value is a state-transition function
      _setClientState(value);

      _setIsDirty(true);
    },
    [isDirty, serverState]
  );

  const reset = useCallback(() => {
    _setIsDirty(false);
    _setClientState(undefined);
  }, []);

  return {
    clientState,
    setClientState,
    isDirty,
    reset,
  };
}

// region commonly-used read endpoints

export const useUser = () =>
  useReadAPI<User>(ApiPaths.users.info._(), {
    lifespan: 300000 /* 5 minutes */,
  });

export const useProcess = (releaseOrDraftProcessId?: string) =>
  useReadAPI<ProcessDetails>(
    releaseOrDraftProcessId
      ? ApiPaths.processes.byId._({
          [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
        })
      : undefined
  );
