import { httpClient } from 'api/apiReqSagaCreator/httpRequest';

import { authCache } from '../api/cache';
import { IServiceFactory } from './serviceFactoryInterface';
import { BareClientRepo } from './services/bareClient/BareClientRepo';
import { IBareClientRepo } from './services/bareClient/IBareClientRepo';
import { ExternalFormsRepo } from './services/externalForms/ExternalFormsRepo';
import { IExternalFormsRepo } from './services/externalForms/IExternalForms';
import { FieldAliasesRepo } from './services/fieldAliases/FieldAliasesRepo';
import { IFieldAliasesRepo } from './services/fieldAliases/IFieldAliases';
import { FreshdeskTokenRepo } from './services/freshdeskToken/freshdeskTokenRepo';
import { IFreshdeskTokenRepo } from './services/freshdeskToken/IFreshdeskTokenRepo';
import { GridRepo } from './services/grid/GridRepo';
import { IGridRepo } from './services/grid/IGridRepo';
import { IIndexingDocumentsRepo } from './services/indexingDocuments/IIndexingDocumentsRepo';
import { IndexingDocumentRepo } from './services/indexingDocuments/IndexingDocumentRepo';
import { IIndexingSourcesRepo } from './services/indexingSources/IIndexingSourcesRepo';
import { IndexingSourcesRepo } from './services/indexingSources/IndexingSourcesRepo';
import { IManagementEmailRepo } from './services/managementEmail/IManagementEmailRepo';
import { ManagementEmailRepo } from './services/managementEmail/ManagementEmailRepo';
import { IManagementLogsRepo } from './services/managementLogs/IManagementLogsRepo';
import { ManagementLogsRepo } from './services/managementLogs/ManagementLogsRepo';
import { IPrintRenderLogRepo } from './services/printRenderLogs/IPrintRenderRepo';
import { PrintRenderLogsRepo } from './services/printRenderLogs/printRenderLogsRepo';
import { IProcessRepo } from './services/process/IProcessRepo';
import { ProcessRepo } from './services/process/ProcessRepo';
import { IProcessInstanceRepo } from './services/ProcessInstance/IProcessInstanceRepo';
import { ProcessInstanceRepo } from './services/ProcessInstance/ProcessInstanceRepo';
import { IProcessInstanceRepoV2 } from './services/ProcessInstanceV2/IProcessInstanceRepo';
import { ProcessInstanceRepoV2 } from './services/ProcessInstanceV2/ProcessInstanceRepo';
import { IScanRepo } from './services/Scan/IScanRepo';
import { ScanRepo } from './services/Scan/scanRepo';
import { IScanScenarioRepo } from './services/ScanScenario/IScanScenarioRepo';
import { ScanScenarioRepo } from './services/ScanScenario/ScanScenarioRepo';
import { ISecurityRepo } from './services/security/ISecurityRepo';
import { SecurityRepo } from './services/security/SecurityRepo';
import { ISystemLogsRepo } from './services/systemLogs/ISystemLogsRepo';
import { SystemLogsRepo } from './services/systemLogs/systemLogsRepo';
import { IUserRepo } from './services/users/IUserRepo';
import { UserRepo } from './services/users/UserRepo';

export interface ServiceContext {
  tokenStateAccessor: () => Tokens;
}

export class ServiceFactory implements IServiceFactory {
  private static tokenStateAccessor() {
    return {
      accessToken: authCache.authToken,
      refreshToken: authCache.exchangeToken,
    } as Tokens;
  }

  private static serviceContext: ServiceContext = {
    tokenStateAccessor: ServiceFactory.tokenStateAccessor,
  };

  public getScanRepo(): IScanRepo {
    return new ScanRepo(httpClient, ServiceFactory.serviceContext);
  }

  public getPrintRenderRepo(): IPrintRenderLogRepo {
    return new PrintRenderLogsRepo(httpClient, ServiceFactory.serviceContext);
  }

  public getIndexingSourceRepo(): IIndexingSourcesRepo {
    return new IndexingSourcesRepo(httpClient, ServiceFactory.serviceContext);
  }

  public getIndexingDocumentsRepo(): IIndexingDocumentsRepo {
    return new IndexingDocumentRepo(httpClient, ServiceFactory.serviceContext);
  }

  public getProcessInstanceRepo(): IProcessInstanceRepo {
    return new ProcessInstanceRepo(httpClient, ServiceFactory.serviceContext);
  }

  public getProcessInstanceRepoV2(): IProcessInstanceRepoV2 {
    return new ProcessInstanceRepoV2(httpClient, ServiceFactory.serviceContext);
  }

  public getScanScenarioRepo(): IScanScenarioRepo {
    return new ScanScenarioRepo(httpClient, ServiceFactory.serviceContext);
  }

  public getFreshdeskTokenRepo(): IFreshdeskTokenRepo {
    return new FreshdeskTokenRepo(httpClient, ServiceFactory.serviceContext);
  }

  public getSystemLogsRepo(): ISystemLogsRepo {
    return new SystemLogsRepo(httpClient, ServiceFactory.serviceContext);
  }

  public getManagementLogsRepo(): IManagementLogsRepo {
    return new ManagementLogsRepo(httpClient, ServiceFactory.serviceContext);
  }

  public getUserRepo(): IUserRepo {
    return new UserRepo(httpClient, ServiceFactory.serviceContext);
  }

  public getManagementEmailRepo(): IManagementEmailRepo {
    return new ManagementEmailRepo(httpClient, ServiceFactory.serviceContext);
  }

  public getProcessRepo(): IProcessRepo {
    return new ProcessRepo(httpClient, ServiceFactory.serviceContext);
  }

  public getBareClientRepo(): IBareClientRepo {
    return new BareClientRepo(httpClient, ServiceFactory.serviceContext);
  }

  public getFieldAliasRepo(): IFieldAliasesRepo {
    return new FieldAliasesRepo(httpClient, ServiceFactory.serviceContext);
  }

  public getGridRepo(): IGridRepo {
    return new GridRepo(httpClient, ServiceFactory.serviceContext);
  }

  public getExternalFormsRepo(): IExternalFormsRepo {
    return new ExternalFormsRepo(httpClient, ServiceFactory.serviceContext);
  }

  public getSecurityRepo(): ISecurityRepo {
    return new SecurityRepo(httpClient, ServiceFactory.serviceContext);
  }
}
