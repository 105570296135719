import { getLocation } from 'connected-react-router';
import { call, put, select } from 'redux-saga/effects';

import { ApiSagaResult } from 'api/apiReqSagaCreator';
import ProcessFields, {
  FieldGeneral,
  GetFieldsByIdResult,
} from 'api/endpoints/processFields';

import ApiUrlParams from 'constants/apiUrlParams';

import { ProcessFields as ProcessFieldsActions } from '../actions';

export interface FetchFieldsParams {
  releaseOrDraftProcessId: string;
}

function* fetchFields(data: FetchFieldsParams) {
  const { query } = yield select(getLocation);

  const { result: fieldsResult }: ApiSagaResult<GetFieldsByIdResult> =
    yield call(ProcessFields.getFieldsById, {
      [ApiUrlParams.releaseOrDraftProcessId]: data?.releaseOrDraftProcessId,
      ...query,
    });

  const dataModelFields =
    (fieldsResult as GetFieldsByIdResult)?.data ||
    ([].map((filedItem: FieldGeneral) => filedItem) as FieldGeneral[]);

  yield put(
    ProcessFieldsActions.setProcessFields({
      data: dataModelFields,
      total: (fieldsResult as GetFieldsByIdResult).total ?? null,
    })
  );

  return {};
}

export default fetchFields;
