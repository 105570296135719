import { AxiosError, AxiosResponse } from 'axios';

import { parseJSONArrayBuffer } from 'utils/parseJson';

export interface ApiError {
  data: BackendError[];
  status: number;
  statusText: string;
  clientErrorString?: boolean;
}
export type ApiResponse<T> = T | ApiError;
export type ApiResponseAsync<T> = Promise<ApiResponse<T>>;

export function isApiError<T>(result: ApiResponse<T>): result is ApiError {
  if (result === null || result === undefined) return false;

  let cast = result as ApiError;
  return (
    Array.isArray(cast.data) &&
    cast.data.every((err) => isBackendError(err)) &&
    typeof cast.status === 'number' &&
    typeof cast.statusText === 'string'
  );
}

function isBackendError(result: any): result is BackendError {
  const cast = result as BackendError;

  return (
    typeof cast.description === 'string' &&
    typeof cast.code === 'number' &&
    (typeof cast.field === 'string' || cast.field === undefined)
  );
}

function isBackendErrors(result: any): result is BackendErrors {
  const cast = result as BackendErrors;

  return (
    Array.isArray(cast.errors) &&
    cast.errors.every((err) => isBackendError(err))
  );
}

// @TODO: convert to ApplicationErrors instead.
// questions: for ApplicationErrors, what should we do when there's multiple errors?
// reminder: error.request is an XMLHttpRequest. store it when we build an ApplicationError.
export const translateAxiosErrorToApiError = (error: AxiosError): ApiError => {
  const wrapErrors = (
    errors: BackendError[],
    clientErrors?: boolean
  ): ApiError => ({
    status: error?.response?.status ?? 500,
    statusText: error?.response?.statusText ?? '',
    data: errors,
    clientErrorString: clientErrors,
  });

  // request sent; response recieved
  if (error.response) {
    // we have error data
    if (error.response.data) {
      let responseData = error.response.data;

      if (responseData instanceof ArrayBuffer) {
        const superguard = (o: any): o is BackendError | BackendErrors =>
          isBackendError(o) || isBackendErrors(o);

        // reminder: the second element of this array is an error message. use it when we switch this to ApplicationErrors.
        const [parsedResponseData] = parseJSONArrayBuffer(
          responseData,
          superguard
        );

        if (parsedResponseData === null)
          // reminder: we can dump response buffer contents with `new TextDecoder().decode(responseData)`
          return wrapErrors([
            {
              description: `Server returned an error: ${error.message}`,
              code: 500,
            },
          ]);

        responseData = parsedResponseData;
      }

      if (isBackendError(responseData)) return wrapErrors([responseData]);
      if (isBackendErrors(responseData)) return wrapErrors(responseData.errors);
      // if we're here: we have an error response, we have data for it, but it's not in a format we know to handle. what should we do?
    }

    return wrapErrors(
      [
        {
          description: error.response.statusText ?? 'unknown error',
          code: error.response.status,
        },
      ],
      true
    );
  }

  // error in producing request or other failure
  return wrapErrors(
    [
      {
        description: error.message ?? 'unknown error',
        code: 400,
      },
    ],
    true
  );
};

/** Returns a fulfulled promise with a successful response, or a rejected promise with an ApiError */
export const axiosResponseToApiResponse = <Data>(
  response: Promise<AxiosResponse<Data>>
): Promise<Data> =>
  response
    .then((response) => response.data)
    .catch((error) => {
      throw translateAxiosErrorToApiError(error);
    });
