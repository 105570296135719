import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import createAction from 'utils/createAction';
import createAsyncActions from 'utils/createAsyncActions';
import createAsyncActionTypes from 'utils/createAsyncActionTypes';

import { RequestActionMeta } from './apiReqSagaCreator';

const API = {
  AUTH: {
    TOKEN_RECEIVED: 'API.AUTH.TOKEN_RECEIVED',
    TOKEN_EXPIRED: 'API.AUTH.TOKEN_EXPIRED',
    CACHED_TOKEN_VERIFIED: 'API.AUTH.CACHED_TOKEN_VERIFIED',
  },
  EXCHANGE: {
    START: 'API.EXCHANGE.START',
    FINISH: 'API.EXCHANGE.FINISH',
  },
  SUBMIT_SIGN_IN: 'API.SIGN_IN',
  SIGNED_IN: 'API.SIGNED_IN',
  SIGNED_OUT: 'API.SIGNED_OUT',
  XHR: createAsyncActionTypes('API.XHR'),
};

const ApiActions = {
  xhr: createAsyncActions<
    AxiosRequestConfig,
    AxiosResponse,
    AxiosError<{ errors: BackendError[] }> & {
      errors: BackendError[];
    },
    RequestActionMeta<unknown>
  >(API.XHR),
  auth: {
    submitSignIn: createAction(API.SUBMIT_SIGN_IN),
    tokenReceived: createAction<string>(API.AUTH.TOKEN_RECEIVED),
    tokenExpired: createAction(API.AUTH.TOKEN_EXPIRED),
    cachedTokenVerified: createAction<boolean>(API.AUTH.CACHED_TOKEN_VERIFIED),
  },
  signedIn: createAction(API.SIGNED_IN),
  signedOut: createAction(API.SIGNED_OUT),
  exchange: {
    start: createAction(API.EXCHANGE.START),
    finish: createAction(API.EXCHANGE.FINISH),
  },
};

const API_REQ = {
  SESSION: {
    LOGIN: createAsyncActionTypes('API_REQ.SESSION.LOGIN'),
    LOG_OUT: createAsyncActionTypes('API_REQ.SESSION.LOG_OUT'),
    RESTORE: createAsyncActionTypes('API_REQ.SESSION.RESTORE'),
    FORGOT_PASSWORD: createAsyncActionTypes('API_REQ.SESSION.FORGOT_PASSWORD'),
    VALIDATE_FORGOT_PASSWORD: createAsyncActionTypes(
      'API_REQ.VALIDATE_FORGOT_PASSWORD'
    ),
    RESTORE_PASSWORD: createAsyncActionTypes(
      'API_REQ.SESSION.RESTORE_PASSWORD'
    ),
  },

  INSTANCE: {
    RE_DO_STEP: createAsyncActionTypes('API_REQ.INSTANCE.RE_DO_STEP'),
    GET_INSTANCE_BY_ID: createAsyncActionTypes(
      'API_REQ.INSTANCE.GET_INSTANCE_BY_ID'
    ),
    FINISH_INSTANCE: createAsyncActionTypes('API_REQ.INSTANCE.FINISH_INSTANCE'),
    UPDATE_INSTANCE: createAsyncActionTypes('API_REQ.INSTANCE.UPDATE_INSTANCE'),
    NEXT_INSTANCE_TASK: createAsyncActionTypes(
      'API_REQ.INSTANCE.NEXT_INSTANCE_TASK'
    ),
    SKIP_INSTANCE_TASK: createAsyncActionTypes(
      'API_REQ.INSTANCE.SKIP_INSTANCE_TASK'
    ),
    UPDATE_FILE_FIELD_VALUE: createAsyncActionTypes(
      'API_REQ.INSTANCE.UPDATE_FILE_FIELD_VALUE'
    ),
    SEND_FILES_VIA_EMAIL: createAsyncActionTypes(
      'API_REQ.INSTANCE.SEND_FILES_VIA_EMAIL'
    ),
  },

  PROCESSES: {
    GET_ALL: createAsyncActionTypes('API_REQ.PROCESSES.GET_ALL'),
    GET_BY_ID: createAsyncActionTypes('API_REQ.PROCESSES.GET_BY_ID'),
    CREATE: createAsyncActionTypes('API_REQ.PROCESSES.CREATE'),
    CREATE_MODEL: createAsyncActionTypes('API_REQ.PROCESSES.CREATE_MODEL'),
    ACTIVATE: createAsyncActionTypes('API_REQ.PROCESSES.ACTIVATE'),
    DEACTIVATE: createAsyncActionTypes('API_REQ.PROCESSES.DEACTIVATE'),
    GET_ALL_FORMS: createAsyncActionTypes('API_REQ.PROCESSES.GET_ALL_FORMS'),
    GET_FORM_BY_ID: createAsyncActionTypes('API_REQ.PROCESSES.GET_FORM_BY_ID'),
    CREATE_FORM: createAsyncActionTypes('API_REQ.PROCESSES.CREATE_FORM'),
    UPLOAD_FILE: createAsyncActionTypes('API_REQ.PROCESSES.UPLOAD_FILE'),
    GET_UPLOADED_FILE: createAsyncActionTypes(
      'API_REQ.PROCESSES.GET_UPLOADED_FILE'
    ),
    GET_UPLOADED_FILE_PREVIEW: createAsyncActionTypes(
      'API_REQ.PROCESSES.GET_UPLOADED_FILE_PREVIEW'
    ),
    SEARCH_BY_ID: createAsyncActionTypes('API_REQ.PROCESSES.SEARCH_BY_ID'),
    UPDATE_BY_ID: createAsyncActionTypes('API_REQ.PROCESSES.UPDATE_BY_ID'),
    DELETE_BY_ID: createAsyncActionTypes('API_REQ.PROCESSES.DELETE_BY_ID'),
    EXPORT: createAsyncActionTypes('API_REQ.PROCESSES.EXPORT'),
    IMPORT: createAsyncActionTypes('API_REQ.PROCESSES.IMPORT'),
    IMPORT_IN: createAsyncActionTypes('API_REQ.PROCESSES.IMPORT_IN'),
    VERSIONS: {
      GET_ALL: createAsyncActionTypes('API_REQ.PROCESSES.VERSIONS.GET_ALL'),
      GET_DETAILS: createAsyncActionTypes(
        'API_REQ.PROCESSES.VERSIONS.GET_DETAILS'
      ),
      ACTIVATE: createAsyncActionTypes('API_REQ.PROCESSES.VERSIONS.ACTIVATE'),
      CREATE: createAsyncActionTypes('API_REQ.PROCESSES.VERSIONS.CREATE'),
    },
    SECURITY_GROUPS: {
      GET_LIST: createAsyncActionTypes(
        'API_REQ.PROCESSES.SECURITY_GROUPS.GET_LIST'
      ),
      GET_AVAILABLE_USERS: createAsyncActionTypes(
        'API_REQ.PROCESSES.SECURITY_GROUPS.GET_AVAILABLE_USERS'
      ),
      ADD_MEMBERS: createAsyncActionTypes(
        'API_REQ.PROCESSES.SECURITY_GROUPS.ADD_MEMBERS'
      ),
      REMOVE_MEMBERS: createAsyncActionTypes(
        'API_REQ.PROCESSES.SECURITY_GROUPS.REMOVE_MEMBERS'
      ),
      GET_DETAILS: createAsyncActionTypes(
        'API_REQ.PROCESSES.SECURITY_GROUPS.GET_DETAILS'
      ),
      GET_GROUP_MEMBERS: createAsyncActionTypes(
        'API_REQ.PROCESSES.SECURITY_GROUPS.GET_GROUP_MEMBERS'
      ),
      CREATE: createAsyncActionTypes(
        'API_REQ.PROCESSES.SECURITY_GROUPS.CREATE'
      ),
      UPDATE: createAsyncActionTypes(
        'API_REQ.PROCESSES.SECURITY_GROUPS.UPDATE'
      ),
      REMOVE: createAsyncActionTypes(
        'API_REQ.PROCESSES.SECURITY_GROUPS.REMOVE'
      ),
    },
  },

  FORM: {
    DELETE_FORM_BY_ID: createAsyncActionTypes('API_REQ.FORM.DELETE_FORM_BY_ID'),
    UPDATE_FORM_BY_ID: createAsyncActionTypes('API_REQ.FORM.UPDATE_FORM_BY_ID'),
    GET_INITIAL_FORM_BY_ID: createAsyncActionTypes(
      'API_REQ.FORM.GET_INITIAL_FORM_BY_ID'
    ),
  },

  FIELDS: {
    GET_BY_PROCESS_ID: createAsyncActionTypes(
      'API_REQ.FIELDS.GET_BY_PROCESS_ID'
    ),
    GET_BY_PROCESS_ID_AND_FIELD_ID: createAsyncActionTypes(
      'API_REQ.FIELDS.GET_BY_PROCESS_ID_AND_FIELD_ID'
    ),
    GET_MODEL_FIELDS_BY_ID: createAsyncActionTypes(
      'API_REQ.FIELDS.GET_MODEL_FIELDS_BY_ID'
    ),
    UPDATE_MODEL_FIELD_BY_ID: createAsyncActionTypes(
      'API_REQ.FIELDS.UPDATE_MODEL_FIELD_BY_ID'
    ),
    GET_BY_PROCESS_TYPE_ID: createAsyncActionTypes(
      'API_REQ.FIELDS.GET_BY_PROCESS_TYPE_ID'
    ),
    DELETE_MODEL_FIELD_BY_ID: createAsyncActionTypes(
      'API_REQ.FIELDS.DELETE_MODEL_FIELD_BY_ID'
    ),
    GET_ALIASES: createAsyncActionTypes('API_REQ.FIELDS.GET_ALIASES'),
  },

  FUNCTIONS: {
    CREATE: createAsyncActionTypes('API_REQ.FUNCTIONS.CREATE'),
    GET_LIST: createAsyncActionTypes('API_REQ.FUNCTIONS.GET_LIST'),
    UPDATE: createAsyncActionTypes('API_REQ.FUNCTIONS.UPDATE'),
    DELETE: createAsyncActionTypes('API_REQ.FUNCTIONS.DELETE'),
    GET_DETAILS: createAsyncActionTypes('API_REQ.FUNCTIONS.GET_DETAILS'),
  },

  STAGES: {
    CREATE: createAsyncActionTypes('API_REQ.STAGES.CREATE'),
    GET_LIST: createAsyncActionTypes('API_REQ.STAGES.GET_LIST'),
    UPDATE: createAsyncActionTypes('API_REQ.STAGES.UPDATE'),
    DELETE: createAsyncActionTypes('API_REQ.STAGES.DELETE'),
    GET_DETAILS: createAsyncActionTypes('API_REQ.STAGES.GET_DETAILS'),
  },

  SCANNING: {
    CREATE: createAsyncActionTypes('API_REQ.SCANNING.CREATE'),
    GET_BY_SCANNING_ID: createAsyncActionTypes('SCANNING.GET_BY_SCANNING_ID'),
    DELETE_BY_SCANNING_ID: createAsyncActionTypes(
      'SCANNING.DELETE_BY_SCANNING_ID'
    ),
    UPDATE_BY_SCANNING_ID: createAsyncActionTypes(
      'SCANNING.UPDATE_BY_SCANNING_ID'
    ),
    DELETE_PAGE_BY_SCANNING_ID: createAsyncActionTypes(
      'SCANNING.DELETE_PAGE_BY_SCANNING_ID'
    ),
    DELETE_PAGES_BY_SCANNING_ID: createAsyncActionTypes(
      'SCANNING.DELETE_PAGES_BY_SCANNING_ID'
    ),
    GET_LIST: createAsyncActionTypes('API_REQ.SCANNING.GET_LIST'),
    GET_DOCUMENTS_BY_SCANNING_ID: createAsyncActionTypes(
      'API_REQ.SCANNING.GET_DOCUMENTS_BY_SCANNING_ID'
    ),
    GET_DOCUMENT_BY_SCANNING_ID: createAsyncActionTypes(
      'API_REQ.SCANNING.GET_DOCUMENT_BY_SCANNING_ID'
    ),
    CREATE_INSTANCE: createAsyncActionTypes('API_REQ.SCANNING.CREATE_INSTANCE'),
    FINISH_SCANNING: createAsyncActionTypes('API_REQ.SCANNING.FINISH_SCANNING'),
    PAGES: {
      SAVE: createAsyncActionTypes('API_REQ.SCANNING.PAGES.SAVE'),
      TRANSFORM_PAGE: createAsyncActionTypes(
        'API_REQ.SCANNING.PAGES.TRANSFORM_PAGE'
      ),
      GET_PAGE: createAsyncActionTypes('API_REQ.SCANNING.PAGES.GET_PAGE'),
      GET_PAGES_PREVIEW: createAsyncActionTypes(
        'API_REQ.SCANNING.PAGES.GET_PAGES_PREVIEW'
      ),
      UPDATE_PAGE: createAsyncActionTypes('API_REQ.SCANNING.PAGES.UPDATE_PAGE'),
    },
    DOCUMENTS: {
      CREATE: createAsyncActionTypes('API_REQ.SCANNING.DOCUMENTS.CREATE'),
      GET_LIST: createAsyncActionTypes('API_REQ.SCANNING.DOCUMENTS.GET_LIST'),
      GET_ALL_LIST: createAsyncActionTypes(
        'API_REQ.SCANNING.DOCUMENTS.GET_ALL_LIST'
      ),
      GET_DOCUMENT: createAsyncActionTypes(
        'API_REQ.SCANNING.DOCUMENTS.GET_DOCUMENT'
      ),
      UPDATE: createAsyncActionTypes('API_REQ.SCANNING.DOCUMENTS.UPDATE'),
      BULK_UPDATE: createAsyncActionTypes(
        'API_REQ.SCANNING.DOCUMENTS.BULK_UPDATE'
      ),
    },
  },

  SCANNING_CONFIGURATION: {
    DELETE_BY_ID: createAsyncActionTypes('SCANNING.DELETE_BY_ID'),
    UPDATE_BY_ID: createAsyncActionTypes('SCANNING.UPDATE_BY_ID'),
  },

  FILES: {
    GET_BY_URL: createAsyncActionTypes('API_REQ.FILES.GET_BY_URL'),
    CHECK_FILE: createAsyncActionTypes('API_REQ.FILES.CHECK_FILE'),
  },

  LICENSES: {
    GET_LICENSES: createAsyncActionTypes('API_REQ.LICENSES.GET_LICENSES'),
  },

  REPLY_TO: {
    CHANGE_REPLY_TO: createAsyncActionTypes('API_REQ.REPLY_TO.CHANGE_REPLY_TO'),
  },

  INVITATIONS: {
    GET_INVITATIONS: createAsyncActionTypes(
      'API_REQ.INVITATIONS.GET_INVITATIONS'
    ),
    GET_INVITATION_TENANTS: createAsyncActionTypes(
      'API_REQ.INVITATIONS.GET_INVITATION_TENANTS'
    ),
    RE_INVITE_USER: createAsyncActionTypes(
      'API_REQ.INVITATIONS.RE_INVITE_USER'
    ),
    REVOKE_USER_INVITATION: createAsyncActionTypes(
      'API_REQ.INVITATIONS.REVOKE_USER_INVITATION'
    ),
    INVITE_USER: createAsyncActionTypes('API_REQ.INVITATIONS.INVITE_USER'),
  },

  USERS: {
    GET_USERS: createAsyncActionTypes('API_REQ.USERS.GET_USERS'),
    INFO: createAsyncActionTypes('API_REQ.USERS.INFO'),
    ACTIVATE_USER: createAsyncActionTypes('API_REQ.USERS.ACTIVATE_USER'),
    DEACTIVATE_USER: createAsyncActionTypes('API_REQ.USERS.DEACTIVATE_USER'),
    GET_USER_DETAILS: createAsyncActionTypes('API_REQ.USERS.GET_USER_DETAILS'),
    UPDATE_USER_DETAILS: createAsyncActionTypes(
      'API_REQ.USERS.UPDATE_USER_DETAILS'
    ),
    UPDATE_USER_PERMISSIONS: createAsyncActionTypes(
      'API_REQ.USERS.UPDATE_USER_PERMISSIONS'
    ),

    GET_USER_TENANTS: createAsyncActionTypes('API_REQ.USER.GET_USER_TENANTS'),
    UPDATE_USER_ACTIVE_TENANT: createAsyncActionTypes(
      'API_REQ.USER.UPDATE_USER_ACTIVE_TENANT'
    ),
  },

  TENANTS: {
    GET_TENANTS: createAsyncActionTypes('API_REQ.TENANTS.GET_TENANTS'),
    ADD_USERS_TO_TENANT: createAsyncActionTypes(
      'API_REQ.TENANTS.ADD_USERS_TO_TENANT'
    ),
    REMOVE_USER_FROM_TENANT: createAsyncActionTypes(
      'API_REQ.TENANTS.REMOVE_USER_FROM_TENANT'
    ),
    UPDATE_USER_IN_TENANT: createAsyncActionTypes(
      'API_REQ.TENANTS.UPDATE_USER_IN_TENANT'
    ),
    ADD_TENANT: createAsyncActionTypes('API_REQ.TENANTS.ADD_TENANT'),
    UPDATE_TENANT: createAsyncActionTypes('API_REQ.TENANTS.UPDATE_TENANT'),
    DELETE_TENANT: createAsyncActionTypes('API_REQ.TENANTS.DELETE_TENANT'),
  },

  TMS_CONNECTIONS: {
    GET_CONNECTIONS: createAsyncActionTypes(
      'API_REQ.TMS_CONNECTIONS.GET_CONNECTIONS'
    ),
    ADD_CONNECTION: createAsyncActionTypes(
      'API_REQ.TMS_CONNECTIONS.ADD_CONNECTION'
    ),
    UPDATE_CONNECTION: createAsyncActionTypes(
      'API_REQ.TMS_CONNECTIONS.UPDATE_CONNECTION'
    ),
    DELETE_CONNECTION: createAsyncActionTypes(
      'API_REQ.TMS_CONNECTIONS.DELETE_CONNECTION'
    ),
    GET_CONNECTION_DETAILS: createAsyncActionTypes(
      'API_REQ.TMS_CONNECTIONS.GET_CONNECTION_DETAILS'
    ),
    VALIDATE_CREDENTIALS: createAsyncActionTypes(
      'API_REQ.TMS_CONNECTIONS.VALIDATE_CREDENTIALS'
    ),
    GET_ENDPOINTS: createAsyncActionTypes(
      'API_REQ.TMS_CONNECTIONS.RECEIVE_ENDPOINTS'
    ),
    TEST_ENDPOINT_PARAMS: createAsyncActionTypes(
      'API_REQ.TMS_CONNECTIONS.GET_ENDPOINTS'
    ),
  },

  FTP_CONNECTIONS: {
    GET_CONNECTIONS: createAsyncActionTypes(
      'API_REQ.FTP_CONNECTIONS.GET_CONNECTIONS'
    ),
    ADD_CONNECTION: createAsyncActionTypes(
      'API_REQ.FTP_CONNECTIONS.ADD_CONNECTION'
    ),
    UPDATE_CONNECTION: createAsyncActionTypes(
      'API_REQ.FTP_CONNECTIONS.UPDATE_CONNECTION'
    ),
    DELETE_CONNECTION: createAsyncActionTypes(
      'API_REQ.FTP_CONNECTIONS.DELETE_CONNECTION'
    ),
    GET_CONNECTION_DETAILS: createAsyncActionTypes(
      'API_REQ.FTP_CONNECTIONS.GET_CONNECTION_DETAILS'
    ),
    VALIDATE_CREDENTIALS: createAsyncActionTypes(
      'API_REQ.FTP_CONNECTIONS.VALIDATE_CREDENTIALS'
    ),
    GET_ENDPOINTS: createAsyncActionTypes(
      'API_REQ.FTP_CONNECTIONS.RECEIVE_ENDPOINTS'
    ),
    TEST_ENDPOINT_PARAMS: createAsyncActionTypes(
      'API_REQ.FTP_CONNECTIONS.GET_ENDPOINTS'
    ),
  },

  PRINT_RENDER: {
    CREATE: createAsyncActionTypes('API_REQ.PRINT_RENDER.CREATE'),
    GET_ALL: createAsyncActionTypes('API_REQ.PRINT_RENDER.GET_ALL'),
    GET_BY_ID: createAsyncActionTypes('API_REQ.PRINT_RENDER.GET_BY_ID'),
    ACTIVATE: createAsyncActionTypes('API_REQ.PRINT_RENDER.ACTIVATE'),
    DE_ACTIVATE: createAsyncActionTypes('API_REQ.PRINT_RENDER.DE_ACTIVATE'),
    DELETE_BY_ID: createAsyncActionTypes('API_REQ.PRINT_RENDER.DELETE_BY_ID'),
    GET_INSTANCES: createAsyncActionTypes('API_REQ.PRINT_RENDER.GET_INSTANCES'),
    UPDATE_BY_ID: createAsyncActionTypes('API_REQ.PRINT_RENDER.UPDATE_BY_ID'),
    UPLOAD_FILE: createAsyncActionTypes('API_REQ.PRINT_RENDER.UPLOAD_FILE'),
    UPDATE_FILE: createAsyncActionTypes('API_REQ.PRINT_RENDER.UPDATE_FILE'),
    EMAIL_TEMPLATES: {
      GET_LIST: createAsyncActionTypes(
        'API_REQ.PRINT_RENDER.EMAIL_TEMPLATES.GET_LIST'
      ),
      CREATE: createAsyncActionTypes(
        'API_REQ.PRINT_RENDER.EMAIL_TEMPLATES.CREATE'
      ),
      UPDATE: createAsyncActionTypes(
        'API_REQ.PRINT_RENDER.EMAIL_TEMPLATES.UPDATE'
      ),
      DELETE: createAsyncActionTypes(
        'API_REQ.PRINT_RENDER.EMAIL_TEMPLATES.DELETE'
      ),
      DETAILS: createAsyncActionTypes(
        'API_REQ.PRINT_RENDER.EMAIL_TEMPLATES.DETAILS'
      ),
      GET_IS_USED: createAsyncActionTypes(
        'API_REQ.PRINT_RENDER.EMAIL_TEMPLATES.GET_IS_USED'
      ),
    },
  },

  SEARCH_TEMPLATE: {
    GET_ALL: createAsyncActionTypes('API_REQ.SEARCH_TEMPLATE.GET_ALL'),
    CREATE: createAsyncActionTypes('API_REQ.SEARCH_TEMPLATE.CREATE'),
    REMOVE: createAsyncActionTypes('API_REQ.SEARCH_TEMPLATE.REMOVE'),
  },

  EXTERNAL: {
    FORMS: {
      VALIDATE: createAsyncActionTypes('API_REQ.EXTERNAL.FORMS.VALIDATE'),
      SUBMIT: createAsyncActionTypes('API_REQ.EXTERNAL.FORMS.SUBMIT'),
      RESEND: createAsyncActionTypes('API_REQ.EXTERNAL.FORMS.RESEND'),
    },
    UPLOAD: createAsyncActionTypes('API_REQ.EXTERNAL.UPLOAD'),
  },

  GRID: {
    SAVE_TEMPLATE: createAsyncActionTypes('API_REQ.GRID.SAVE_TEMPLATE'),
    GET_ALL_TEMPLATES: createAsyncActionTypes('API_REQ.GRID.GET_ALL_TEMPLATES'),
    ACTIVATE_TEMPLATE: createAsyncActionTypes('API_REQ.GRID.ACTIVATE_TEMPLATE'),
    REMOVE_TEMPLATE: createAsyncActionTypes('API_REQ.GRID.REMOVE_TEMPLATE'),
  },

  INBOUND_EMAIL: {
    SAVE_SCENARIO: createAsyncActionTypes(
      'API_REQ.INBOUND_EMAIL.SAVE_SCENARIO'
    ),
    UPDATE_SCENARIO: createAsyncActionTypes(
      'API_REQ.INBOUND_EMAIL.UPDATE_SCENARIO'
    ),
    GET_SCENARIOS: createAsyncActionTypes(
      'API_REQ.INBOUND_EMAIL.GET_SCENARIOS'
    ),
    ACTIVATE_SCENARIO: createAsyncActionTypes(
      'API_REQ.INBOUND_EMAIL.ACTIVATE_SCENARIO'
    ),
    DEACTIVATE_SCENARIO: createAsyncActionTypes(
      'API_REQ.INBOUND_EMAIL.DEACTIVATE_SCENARIO'
    ),
    GET_DETAILS: createAsyncActionTypes('API_REQ.INBOUND_EMAIL.GET_DETAILS'),
    DELETE_SCENARIO: createAsyncActionTypes(
      'API_REQ.INBOUND_EMAIL.DELETE_SCENARIO'
    ),
  },

  FTP_POLLING: {
    SAVE_SCENARIO: createAsyncActionTypes('API_REQ.FTP_POLLING.SAVE_SCENARIO'),
    UPDATE_SCENARIO: createAsyncActionTypes(
      'API_REQ.FTP_POLLING.UPDATE_SCENARIO'
    ),
    GET_SCENARIOS: createAsyncActionTypes('API_REQ.FTP_POLLING.GET_SCENARIOS'),
    ACTIVATE_SCENARIO: createAsyncActionTypes(
      'API_REQ.FTP_POLLING.ACTIVATE_SCENARIO'
    ),
    DEACTIVATE_SCENARIO: createAsyncActionTypes(
      'API_REQ.FTP_POLLING.DEACTIVATE_SCENARIO'
    ),
    GET_DETAILS: createAsyncActionTypes('API_REQ.FTP_POLLING.GET_DETAILS'),
    DELETE_SCENARIO: createAsyncActionTypes(
      'API_REQ.FTP_POLLING.DELETE_SCENARIO'
    ),
  },

  EMAILPROVIDERS: {
    VALIDATE_CREDENTIALS: createAsyncActionTypes(
      'API_REQ.EMAILPROVIDERS.VALIDATE_CREDENTIALS'
    ),
    GET_LABELS: createAsyncActionTypes('API_REQ.EMAILPROVIDERS.GET_LABELS'),
  },

  OCR: {
    CREATE_KEYS: createAsyncActionTypes('API_REQ.OCR.CREATE_KEYS'),
    GET_KEYS: createAsyncActionTypes('API_REQ.OCR.GET_KEYS'),
    DELETE_KEY: createAsyncActionTypes('API_REQ.OCR.DELETE_KEY'),
    EDIT_KEY: createAsyncActionTypes('API_REQ.OCR.EDIT_KEY'),
    UPLOAD_FILE: createAsyncActionTypes('API_REQ.OCR.UPLOAD_FILE'),
    TEST_FILE: createAsyncActionTypes('API_REQ.OCR.TEST_FILE'),
    GET_TEST_RESULTS: createAsyncActionTypes('API_REQ.OCR.GET_TEST_RESULTS'),
  },

  SPLIT_DOCUMENT: {
    SPLIT: createAsyncActionTypes('API_REQ.SPLIT_DOCUMENT.SPLIT'),
    CREATE_DOCUMENT: createAsyncActionTypes(
      'API_REQ.SPLIT_DOCUMENT.CREATE_DOCUMENT'
    ),
    GET_DOCUMENTS: createAsyncActionTypes(
      'API_REQ.SPLIT_DOCUMENT.GET_DOCUMENTS'
    ),
    GET_DOCUMENT_BY_ID: createAsyncActionTypes(
      'API_REQ.SPLIT_DOCUMENT.GET_DOCUMENT_BY_ID'
    ),
    BULK_UPDATE_DOCUMENTS: createAsyncActionTypes(
      'API_REQ.SPLIT_DOCUMENT.BULK_UPDATE_DOCUMENTS'
    ),
    DELETE_DOCUMENT: createAsyncActionTypes(
      'API_REQ.SPLIT_DOCUMENT.DELETE_DOCUMENT'
    ),
    UPDATE_DOCUMENT: createAsyncActionTypes(
      'API_REQ.SPLIT_DOCUMENT.UPDATE_DOCUMENT'
    ),
    REMOVE_DOCUMENT_PAGES: createAsyncActionTypes(
      'API_REQ.SPLIT_DOCUMENT.REMOVE_DOCUMENT_PAGES'
    ),
    UPDATE_DOCUMENT_PAGE: createAsyncActionTypes(
      'API_REQ.SPLIT_DOCUMENT.UPDATE_DOCUMENT_PAGE'
    ),
    GET_DOCUMENT_PAGE: createAsyncActionTypes(
      'API_REQ.SPLIT_DOCUMENT.GET_DOCUMENT_PAGE'
    ),
    GET_DOCUMENT_PAGE_PREVIEW: createAsyncActionTypes(
      'API_REQ.SPLIT_DOCUMENT.GET_DOCUMENT_PAGE_PREVIEW'
    ),
    CANCEL: createAsyncActionTypes('API_REQ.SPLIT_DOCUMENT.CANCEL'),
    FINISH: createAsyncActionTypes('API_REQ.SPLIT_DOCUMENT.FINISH'),
    UPDATE_PAGE: createAsyncActionTypes('API_REQ.SPLIT_DOCUMENT.UPDATE_PAGE'),
  },

  SYSTEM_LOGS: {
    GET_ALL: createAsyncActionTypes('API_REQ.GET_SYSTEM_LOGS'),
  },

  PROCESS_SERVICES: {
    GET_ALL: createAsyncActionTypes('API_REQ.PROCESS_SERVICES.GET_ALL'),
    UPDATE_ALL: createAsyncActionTypes('API_REQ.PROCESS_SERVICES.UPDATE_ALL'),
    GET_ETA: createAsyncActionTypes('API_REQ.PROCESS_SERVICES.GET_ETA'),
  },
};

export { API as API_ACTION_TYPES, API_REQ as API_REQ_ACTION_TYPES, ApiActions };
