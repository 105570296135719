export function valueIsString(value: any): value is string {
  return typeof value === 'string';
}

export function valueIsNumber(value: any): value is number {
  return typeof value === 'number';
}

export function valueIsDate(value: any): value is Date {
  return value instanceof Date;
}

export function valueIsArrayOfStrings(value: any): value is string[] {
  return Array.isArray(value) && value.every((item) => valueIsString(item));
}

export function valueIsSelectItem(value: any): value is SelectItem<{}> {
  return Object.hasOwn(value, 'value');
}
