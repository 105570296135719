import { combineReducers } from 'redux';

import { API_REQ_ACTION_TYPES } from 'api/actions';
import {
  GetScenariosParams,
  GetScenariosResult,
  PollingScenarioDetails,
  PollingScenarioGeneral,
} from 'api/endpoints/emailPolling';
import {
  GetLabelsResult,
  ValidateCredentialsResult,
} from 'api/endpoints/emailproviders';

import { EMAIL_POLLING_ACTION_TYPES } from './actions';

export interface EmailPollingState {
  list: Nullable<PollingScenarioGeneral[]>;
  activeScenario: Nullable<PollingScenarioGeneral>;
  labels: Nullable<string[]>;
  areCredentialsValid: boolean;
  details: Nullable<PollingScenarioDetails>;
  emailWasChanged: boolean;
}

interface GetScenariosSuccessAction {
  type: string;
  payload?: GetScenariosResult;
  meta?: { input: GetScenariosParams };
}

const emailPolling = combineReducers<EmailPollingState>({
  list(state = null, { type, payload, meta }: GetScenariosSuccessAction) {
    switch (type) {
      case API_REQ_ACTION_TYPES.INBOUND_EMAIL.GET_SCENARIOS.SUCCESS:
        return meta?.input.isActive ? state : (payload?.data ?? null);

      default:
        return state;
    }
  },
  activeScenario(
    state = null,
    { type, payload, meta }: GetScenariosSuccessAction
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.INBOUND_EMAIL.GET_SCENARIOS.SUCCESS:
        if (!meta?.input.isActive) return state;
        return payload?.data ? payload?.data[0] : null;

      default:
        return state;
    }
  },
  labels(
    state = null,
    { type, payload }: { type: string; payload: GetLabelsResult }
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.EMAILPROVIDERS.GET_LABELS.SUCCESS:
        return [...payload.labels];

      case EMAIL_POLLING_ACTION_TYPES.RESET_CREDENTIALS_FLAG:
      case API_REQ_ACTION_TYPES.EMAILPROVIDERS.VALIDATE_CREDENTIALS.START:
        return null;

      default:
        return state;
    }
  },
  areCredentialsValid(
    state = false,
    { type, payload }: { type: string; payload: ValidateCredentialsResult }
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.EMAILPROVIDERS.VALIDATE_CREDENTIALS.SUCCESS:
        return payload.isValid;

      case EMAIL_POLLING_ACTION_TYPES.RESET_CREDENTIALS_FLAG:
      case API_REQ_ACTION_TYPES.EMAILPROVIDERS.VALIDATE_CREDENTIALS.START:
      case API_REQ_ACTION_TYPES.EMAILPROVIDERS.VALIDATE_CREDENTIALS.ERROR:
        return false;

      default:
        return state;
    }
  },
  details(
    state = null,
    { type, payload }: { type: string; payload: PollingScenarioDetails }
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.INBOUND_EMAIL.GET_DETAILS.SUCCESS:
        return payload;

      case API_REQ_ACTION_TYPES.INBOUND_EMAIL.GET_DETAILS.START:
        return null;

      default:
        return state;
    }
  },
  emailWasChanged(state = false, { type }) {
    switch (type) {
      case EMAIL_POLLING_ACTION_TYPES.SET_EMAIL_WAS_CHANGED:
        return true;

      case API_REQ_ACTION_TYPES.EMAILPROVIDERS.VALIDATE_CREDENTIALS.SUCCESS:
        return false;

      default:
        return state;
    }
  },
});

export default emailPolling;
