import subSelectorCreator from 'utils/subSelectorCreator';

import { ScanConfigState } from './reducer';

const createScanConfigSelector = subSelectorCreator<ScanConfigState>(
  (state) => state.processScanConfig
);

const ScanConfigSelector = {
  getList: createScanConfigSelector(({ list }) => list),
  getFiledDetails: createScanConfigSelector(({ filedDetails }) => filedDetails),
};

export default ScanConfigSelector;
