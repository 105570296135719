import { ApiPaths } from '../../../api/constants';
import { ProcessGeneral } from '../../../api/endpoints/processes';
import {
  SecurityGroupDetails,
  SecurityGroupUserGeneral,
} from '../../../api/endpoints/securityGroups';
import BaseRepo from '../../BaseRepo';
import { ApiResponseAsync } from '../../models';
import { ISecurityRepo } from './ISecurityRepo';

export class SecurityRepo extends BaseRepo implements ISecurityRepo {
  getGroupDetails = (
    draft: ProcessGeneral,
    groupId: string
  ): ApiResponseAsync<SecurityGroupDetails> => {
    const link = ApiPaths.processes.byId.securityGroups.byId._({
      releaseOrDraftProcessId: draft.id,
      groupId,
    });

    return this.helpers.handleGet<SecurityGroupDetails>(link, {});
  };

  getGroupMembers = (
    draft: ProcessGeneral,
    groupId: string
  ): ApiResponseAsync<DataWithTotal<SecurityGroupUserGeneral>> => {
    const link = ApiPaths.processes.byId.securityGroups.byId.members._({
      releaseOrDraftProcessId: draft.id,
      groupId: groupId,
    });
    return this.helpers.handleGet<DataWithTotal<SecurityGroupUserGeneral>>(
      link,
      {}
    );
  };
}
