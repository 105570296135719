import { ApiError } from '../models';

export const buildBadRequestApiError = (err: Error) => {
  const apiError: ApiError = {
    data: [
      {
        description: err.message,
        code: 0,
      },
    ],
    status: 400,
    statusText: 'Bad Request',
  };

  return apiError;
};

export const buildForbiddenApiError = (err: Error) => {
  const apiError: ApiError = {
    data: [
      {
        description: err.message,
        code: 0,
      },
    ],
    status: 403,
    statusText: 'Forbidden',
  };

  return apiError;
};

export const buildUnauthorizedApiError = () => {
  const apiError: ApiError = {
    data: [
      {
        description: 'Token expired',
        code: 0,
      },
    ],
    status: 401,
    statusText: 'Unauthorized',
  };

  return apiError;
};

export const buildNotFoundApiError = () => {
  const apiError: ApiError = {
    data: [
      {
        description: 'Not Found',
        code: 0,
      },
    ],
    status: 404,
    statusText: 'NotFound',
  };

  return apiError;
};

export const buildInternalServerErrorApiError = (err: Error) => {
  const apiError: ApiError = {
    data: [
      {
        description: err.message,
        code: 0,
      },
    ],
    status: 500,
    statusText: 'Internal Server Error',
  };

  return apiError;
};
