import {
  ApiResponseAsync,
  translateAxiosErrorToApiError,
} from 'container/models';

import { LogEntry } from 'components/organisms/logview';

import { authHeaderName } from 'api/apiReqSagaCreator/config';
import { handleExpiredTokens } from 'api/apiReqSagaCreator/httpRequest';
import { OdataURLBuilder } from 'api/types/OdataURLBuilder';

import BaseRepo from '../../BaseRepo';
import { ISystemLogsRepo, Status } from './ISystemLogsRepo';

export class SystemLogsRepo extends BaseRepo implements ISystemLogsRepo {
  getLogs = (request: OdataURLBuilder): ApiResponseAsync<LogEntry[] | number> =>
    handleExpiredTokens(() =>
      this.client.get<LogEntry[] | number>(request.toString(), {
        headers: {
          [authHeaderName]: this.tokenAccessor(),
        },
      })
    )
      .then((response) => response.data)
      .catch(translateAxiosErrorToApiError);

  getStatus = (releaseOrDraftProcessId: string): ApiResponseAsync<Status> =>
    handleExpiredTokens(() =>
      this.client.get<Status>(
        `/processes/${releaseOrDraftProcessId}/service-status`,
        {
          headers: {
            [authHeaderName]: this.tokenAccessor(),
          },
        }
      )
    )
      .then((response) => {
        const data = response.data;

        for (const value of Object.values(data)) {
          if ('lastSuccessDate' in value) {
            value.lastSuccessDate = new Date(value.lastSuccessDate);
          }
          if ('lastFailDate' in value) {
            value.lastFailDate = new Date(value.lastFailDate);
          }
          if ('scheduledTime' in value) {
            value.scheduledTime = new Date(value.scheduledTime);
          }
        }

        return data;
      })
      .catch(translateAxiosErrorToApiError);
}
