import { takeLatest } from 'redux-saga/effects';

import { API_REQ_ACTION_TYPES } from 'api/actions';

import {
  reFetchList,
  showToasterAfterActivate,
  showToasterAfterCreate,
} from './sagas';

function* watchersSaga() {
  yield takeLatest(
    [
      API_REQ_ACTION_TYPES.PROCESSES.VERSIONS.CREATE.SUCCESS,
      API_REQ_ACTION_TYPES.PROCESSES.VERSIONS.ACTIVATE.SUCCESS,
    ],
    reFetchList // get all versions
  );

  yield takeLatest(
    [API_REQ_ACTION_TYPES.PROCESSES.VERSIONS.CREATE.SUCCESS],
    showToasterAfterCreate
  );

  yield takeLatest(
    [API_REQ_ACTION_TYPES.PROCESSES.VERSIONS.ACTIVATE.SUCCESS],
    showToasterAfterActivate
  );
}
export default watchersSaga;
