import pathCreator from 'utils/pathCreator';

import {
  WithEmailTemplateId,
  WithExternalFormId,
  WithFieldId,
  WithFormId,
  WithFTPConnectionId,
  WithFtpPollingScenarioId,
  WithFunctionId,
  WithGroupId,
  WithInboundEmailScenarioId,
  WithInvitationId,
  WithName,
  WithOcrKey,
  WithPagination,
  WithPrintRenderScenarioId,
  WithProcessInstanceId,
  WithProcessVersionId,
  WithReleaseOrDraftProcessId,
  WithScanConfigurationId,
  WithScanDocContainerId,
  WithScanInstanceId,
  WithSearchTemplateId,
  WithSplitDocContainerId,
  WithSplitDocInstanceId,
  WithStageId,
  WithStepId,
  WithTemplateId,
  WithTenantId,
  WithTMSConnectionId,
  WithUserId,
} from './types/pathParams';

export enum HTTP_METHODS {
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  GET = 'GET',
  DELETE = 'DELETE',
  OPTIONS = 'OPTIONS',
}

export enum CONTENT_TYPE {
  APPLICAITON_JSON = 'application/json',
  MULTIPART_FORMDATA = 'multipart/form-data',
}

export enum RESPONSE_TYPE {
  ARRAY_BUFFER = 'arraybuffer',
  BLOB = 'blob',
}

export const ApiPaths = {
  auth: {
    _: pathCreator('/auth'),
    login: pathCreator('/auth/login'),
    refreshToken: pathCreator('/auth/refresh-token'),
    register: pathCreator('/auth/register'),
    validateInvitationOtt: pathCreator('/auth/validate-invitation-ott'),
    forgotPassword: pathCreator('/auth/forgot-password'),
    validateForgotPassword: pathCreator('/auth/validate-forgot-password'),
    restorePassword: pathCreator('/auth/restore-password'),
  },
  gridTemplates: {
    _: pathCreator('/grid-templates'),
    byId: {
      _: pathCreator<WithTemplateId>('/grid-templates/:templateId'),
      activate: {
        _: pathCreator<WithTemplateId>('/grid-templates/:templateId/activate'),
      },
    },
  },
  users: {
    _: pathCreator('/users'),
    logOut: {
      _: pathCreator('/users/logout'),
    },
    info: {
      _: pathCreator('/users/info'),
    },
    invite: {
      _: pathCreator('/users/invite'),
    },
    reInvite: {
      _: pathCreator('/users/re-invite'),
    },
    profile: {
      _: pathCreator('/users/profile'),
    },
    byId: {
      _: pathCreator<WithUserId>('/users/:userId'),
      tenants: {
        _: pathCreator<WithUserId>('/users/:userId/tenants'),
        byId: {
          _: pathCreator<WithUserId & WithTenantId>(
            '/users/:userId/tenants/:tenantId'
          ),
        },
      },
      permissions: {
        _: pathCreator<WithUserId>('/users/:userId/permissions'),
      },
      activate: {
        _: pathCreator<WithUserId>('users/:userId/activate'),
      },
      deactivate: {
        _: pathCreator<WithUserId>('users/:userId/deactivate'),
      },
    },
  },
  field: {
    _: pathCreator('/field'),
    byScanInstanceId: {
      _: pathCreator<WithProcessInstanceId>('/field/:processInstanceId'),
      byFieldId: {
        _: pathCreator<WithProcessInstanceId & WithFieldId>(
          '/field/:processInstanceId/:fieldId'
        ),
        byName: {
          _: pathCreator<WithProcessInstanceId & WithFieldId & WithName>(
            '/field/:processInstanceId/:fieldId/:name'
          ),
          preview: {
            _: pathCreator<WithProcessInstanceId & WithFieldId & WithName>(
              '/field/:processInstanceId/:fieldId/:name/preview'
            ),
          },
        },
      },
    },
  },
  processes: {
    _: pathCreator('/processes'),
    services: {
      _: pathCreator('/processes/services'),
      pollingEta: {
        _: pathCreator('/processes/services/polling-eta'),
      },
    },
    import: {
      _: pathCreator('/processes/import'),
      byTenantId: {
        _: pathCreator<WithTenantId>('/processes/import/:tenantId'),
      },
    },
    byId: {
      _: pathCreator<WithReleaseOrDraftProcessId>(
        '/processes/:releaseOrDraftProcessId'
      ),
      services: {
        _: pathCreator<WithReleaseOrDraftProcessId>(
          '/processes/:releaseOrDraftProcessId/services'
        ),
      },
      sendEmail: {
        _: pathCreator<WithReleaseOrDraftProcessId>(
          '/processes/:releaseOrDraftProcessId/send-email'
        ),
      },
      securityGroups: {
        _: pathCreator<WithReleaseOrDraftProcessId>(
          '/processes/:releaseOrDraftProcessId/security-groups'
        ),
        availableUsers: {
          _: pathCreator<WithReleaseOrDraftProcessId>(
            '/processes/:releaseOrDraftProcessId/security-groups/available-users'
          ),
        },
        byId: {
          _: pathCreator<WithReleaseOrDraftProcessId & WithGroupId>(
            '/processes/:releaseOrDraftProcessId/security-groups/:groupId'
          ),
          members: {
            _: pathCreator<WithReleaseOrDraftProcessId & WithGroupId>(
              '/processes/:releaseOrDraftProcessId/security-groups/:groupId/members'
            ),
            add: {
              _: pathCreator<WithReleaseOrDraftProcessId & WithGroupId>(
                '/processes/:releaseOrDraftProcessId/security-groups/:groupId/members/add'
              ),
            },
            remove: {
              _: pathCreator<WithReleaseOrDraftProcessId & WithGroupId>(
                '/processes/:releaseOrDraftProcessId/security-groups/:groupId/members/remove'
              ),
            },
          },
        },
      },
      ocr: {
        _: pathCreator<WithReleaseOrDraftProcessId>(
          '/processes/:releaseOrDraftProcessId/ocr'
        ),
        keys: {
          _: pathCreator<WithReleaseOrDraftProcessId>(
            '/processes/:releaseOrDraftProcessId/ocr/keys'
          ),
          byId: {
            _: pathCreator<WithReleaseOrDraftProcessId & WithOcrKey>(
              '/processes/:releaseOrDraftProcessId/ocr/keys/:ocrKey'
            ),
          },
        },
        test: {
          _: pathCreator<WithReleaseOrDraftProcessId>(
            '/processes/:releaseOrDraftProcessId/ocr/test'
          ),
        },
      },
      inboundEmail: {
        _: pathCreator<WithReleaseOrDraftProcessId>(
          '/processes/:releaseOrDraftProcessId/inbound-email'
        ),
        launch: {
          _: pathCreator<WithReleaseOrDraftProcessId>(
            '/processes/:releaseOrDraftProcessId/inbound-email/launch'
          ),
        },
        stop: {
          _: pathCreator<WithReleaseOrDraftProcessId>(
            '/processes/:releaseOrDraftProcessId/inbound-email/stop'
          ),
        },
        scenarios: {
          _: pathCreator<WithReleaseOrDraftProcessId>(
            '/processes/:releaseOrDraftProcessId/inbound-email/scenarios'
          ),
          byId: {
            _: pathCreator<
              WithReleaseOrDraftProcessId & WithInboundEmailScenarioId
            >(
              '/processes/:releaseOrDraftProcessId/inbound-email/scenarios/:inboundEmailScenarioId'
            ),
            activate: {
              _: pathCreator<
                WithReleaseOrDraftProcessId & WithInboundEmailScenarioId
              >(
                '/processes/:releaseOrDraftProcessId/inbound-email/scenarios/:inboundEmailScenarioId/activate'
              ),
            },
            deactivate: {
              _: pathCreator(
                '/processes/:releaseOrDraftProcessId/inbound-email/scenarios/:inboundEmailScenarioId/deactivate'
              ),
            },
          },
        },
      },
      ftpPolling: {
        _: pathCreator<WithReleaseOrDraftProcessId>(
          '/processes/:releaseOrDraftProcessId/ftp-polling'
        ),
        launch: {
          _: pathCreator<WithReleaseOrDraftProcessId>(
            '/processes/:releaseOrDraftProcessId/ftp-polling/launch'
          ),
        },
        stop: {
          _: pathCreator<WithReleaseOrDraftProcessId>(
            '/processes/:releaseOrDraftProcessId/ftp-polling/stop'
          ),
        },
        scenarios: {
          _: pathCreator<WithReleaseOrDraftProcessId>(
            '/processes/:releaseOrDraftProcessId/ftp-polling/scenarios'
          ),
          byId: {
            _: pathCreator<
              WithReleaseOrDraftProcessId & WithFtpPollingScenarioId
            >(
              '/processes/:releaseOrDraftProcessId/ftp-polling/scenarios/:ftpPollingScenarioId'
            ),
            activate: {
              _: pathCreator<
                WithReleaseOrDraftProcessId & WithFtpPollingScenarioId
              >(
                '/processes/:releaseOrDraftProcessId/ftp-polling/scenarios/:ftpPollingScenarioId/activate'
              ),
            },
            deactivate: {
              _: pathCreator<
                WithReleaseOrDraftProcessId & WithFtpPollingScenarioId
              >(
                '/processes/:releaseOrDraftProcessId/ftp-polling/scenarios/:ftpPollingScenarioId/deactivate'
              ),
            },
          },
        },
      },

      versions: {
        _: pathCreator<WithReleaseOrDraftProcessId>(
          '/processes/:releaseOrDraftProcessId/versions'
        ),
        byId: {
          _: pathCreator<WithReleaseOrDraftProcessId & WithProcessVersionId>(
            '/processes/:releaseOrDraftProcessId/versions/:processVersionId'
          ),
          activate: {
            _: pathCreator<WithReleaseOrDraftProcessId & WithProcessVersionId>(
              '/processes/:releaseOrDraftProcessId/versions/:processVersionId/activate'
            ),
          },
        },
      },
      deactivate: {
        _: pathCreator<WithReleaseOrDraftProcessId>(
          '/processes/:releaseOrDraftProcessId/deactivate'
        ),
      },
      instances: {
        _: pathCreator<WithReleaseOrDraftProcessId>(
          '/processes/:releaseOrDraftProcessId/instances'
        ),
        byId: {
          _: pathCreator<WithReleaseOrDraftProcessId & WithProcessInstanceId>(
            '/processes/:releaseOrDraftProcessId/instances/:processInstanceId'
          ),
          finish: {
            _: pathCreator<WithReleaseOrDraftProcessId & WithProcessInstanceId>(
              '/processes/:releaseOrDraftProcessId/instances/:processInstanceId/finish'
            ),
          },
          skip: {
            _: pathCreator<WithReleaseOrDraftProcessId & WithProcessInstanceId>(
              '/processes/:releaseOrDraftProcessId/instances/:processInstanceId/skip'
            ),
          },
          continue: {
            _: pathCreator<WithReleaseOrDraftProcessId & WithProcessInstanceId>(
              '/processes/:releaseOrDraftProcessId/instances/:processInstanceId/continue'
            ),
          },
          update: {
            _: pathCreator<WithReleaseOrDraftProcessId & WithProcessInstanceId>(
              '/processes/:releaseOrDraftProcessId/instances/:processInstanceId/update'
            ),
          },
          steps: {
            _: pathCreator<WithReleaseOrDraftProcessId & WithProcessInstanceId>(
              '/processes/:releaseOrDraftProcessId/instances/:processInstanceId/steps'
            ),
            byId: {
              _: pathCreator<
                WithReleaseOrDraftProcessId & WithProcessInstanceId & WithStepId
              >(
                '/processes/:releaseOrDraftProcessId/instances/:processInstanceId/steps/:stepId'
              ),
              reDo: {
                _: pathCreator<
                  WithReleaseOrDraftProcessId &
                    WithProcessInstanceId &
                    WithStepId
                >(
                  '/processes/:releaseOrDraftProcessId/instances/:processInstanceId/steps/:stepId/redo'
                ),
              },
            },
          },
        },
      },
      fields: {
        _: pathCreator<WithReleaseOrDraftProcessId>(
          '/processes/:releaseOrDraftProcessId/fields'
        ),
        aliases: {
          _: pathCreator<WithReleaseOrDraftProcessId>(
            '/processes/:releaseOrDraftProcessId/fields/aliases'
          ),
        },
        byId: pathCreator<WithReleaseOrDraftProcessId & WithFieldId>(
          '/processes/:releaseOrDraftProcessId/fields/:fieldId'
        ),
      },
      processForms: {
        _: pathCreator<WithReleaseOrDraftProcessId & Partial<WithPagination>>(
          '/processes/:releaseOrDraftProcessId/forms'
        ),
        byId: {
          _: pathCreator<WithReleaseOrDraftProcessId & WithFormId>(
            '/processes/:releaseOrDraftProcessId/forms/:formId'
          ),
          initial: {
            _: pathCreator<WithReleaseOrDraftProcessId & WithFormId>(
              '/processes/:releaseOrDraftProcessId/forms/:formId/initial'
            ),
          },
        },
      },
      functions: {
        _: pathCreator<WithReleaseOrDraftProcessId>(
          '/processes/:releaseOrDraftProcessId/functions'
        ),
        byId: {
          _: pathCreator<WithReleaseOrDraftProcessId & WithFunctionId>(
            '/processes/:releaseOrDraftProcessId/functions/:functionId'
          ),
        },
      },
      stages: {
        _: pathCreator<WithReleaseOrDraftProcessId>(
          '/processes/:releaseOrDraftProcessId/stages'
        ),
        byId: {
          _: pathCreator<WithReleaseOrDraftProcessId & WithStageId>(
            '/processes/:releaseOrDraftProcessId/stages/:stageId'
          ),
        },
      },
      split: {
        _: pathCreator<WithReleaseOrDraftProcessId>(
          '/processes/:releaseOrDraftProcessId/split'
        ),
        instances: {
          _: pathCreator<WithReleaseOrDraftProcessId>(
            '/processes/:releaseOrDraftProcessId/split/instances'
          ),
          byId: {
            _: pathCreator<WithReleaseOrDraftProcessId & WithProcessInstanceId>(
              '/processes/:releaseOrDraftProcessId/split/instances/:processInstanceId'
            ),
            fields: {
              _: pathCreator<
                WithReleaseOrDraftProcessId & WithProcessInstanceId
              >(
                '/processes/:releaseOrDraftProcessId/split/instances/:processInstanceId/fields'
              ),
              byId: {
                _: pathCreator<
                  WithReleaseOrDraftProcessId &
                    WithProcessInstanceId &
                    WithFieldId
                >(
                  '/processes/:releaseOrDraftProcessId/split/instances/:processInstanceId/fields/:fieldId'
                ),
                pages: {
                  _: pathCreator<
                    WithReleaseOrDraftProcessId &
                      WithProcessInstanceId &
                      WithFieldId
                  >(
                    '/processes/:releaseOrDraftProcessId/split/instances/:processInstanceId/fields/:fieldId/pages'
                  ),
                  byName: {
                    _: pathCreator<
                      WithReleaseOrDraftProcessId &
                        WithProcessInstanceId &
                        WithFieldId &
                        WithName
                    >(
                      '/processes/:releaseOrDraftProcessId/split/instances/:processInstanceId/fields/:fieldId/pages/:name'
                    ),
                    transform: {
                      _: pathCreator<
                        WithReleaseOrDraftProcessId &
                          WithProcessInstanceId &
                          WithFieldId &
                          WithName
                      >(
                        '/processes/:releaseOrDraftProcessId/split/instances/:processInstanceId/fields/:fieldId/pages/:name/transform'
                      ),
                    },
                    preview: {
                      _: pathCreator<
                        WithReleaseOrDraftProcessId &
                          WithProcessInstanceId &
                          WithFieldId &
                          WithName
                      >(
                        '/processes/:releaseOrDraftProcessId/split/instances/:processInstanceId/fields/:fieldId/pages/:name/preview'
                      ),
                    },
                  },
                },
              },
            },
          },
        },
        byId: {
          _: pathCreator<WithReleaseOrDraftProcessId & WithSplitDocInstanceId>(
            '/processes/:releaseOrDraftProcessId/split/:splitDocInstanceId'
          ),
          cancel: {
            _: pathCreator<
              WithReleaseOrDraftProcessId & WithSplitDocInstanceId
            >(
              '/processes/:releaseOrDraftProcessId/split/:splitDocInstanceId/cancel'
            ),
          },
          finish: {
            _: pathCreator<
              WithReleaseOrDraftProcessId & WithSplitDocInstanceId
            >(
              '/processes/:releaseOrDraftProcessId/split/:splitDocInstanceId/finish'
            ),
          },
          documents: {
            _: pathCreator<
              WithReleaseOrDraftProcessId & WithSplitDocInstanceId
            >(
              '/processes/:releaseOrDraftProcessId/split/:splitDocInstanceId/documents'
            ),
            byId: {
              _: pathCreator<
                WithReleaseOrDraftProcessId &
                  WithSplitDocInstanceId &
                  WithSplitDocContainerId
              >(
                '/processes/:releaseOrDraftProcessId/split/:splitDocInstanceId/documents/:splitDocContainerId'
              ),
              removePages: {
                _: pathCreator<
                  WithReleaseOrDraftProcessId &
                    WithSplitDocInstanceId &
                    WithSplitDocContainerId
                >(
                  '/processes/:releaseOrDraftProcessId/split/:splitDocInstanceId/documents/:splitDocContainerId/remove-pages'
                ),
              },
            },
          },
        },
      },
      activate: pathCreator<WithReleaseOrDraftProcessId>(
        '/processes/:releaseOrDraftProcessId/activate'
      ),
      scanning: {
        _: pathCreator<WithReleaseOrDraftProcessId>(
          '/processes/:releaseOrDraftProcessId/scan'
        ),
        configurations: {
          _: pathCreator<WithReleaseOrDraftProcessId>(
            '/processes/:releaseOrDraftProcessId/scan/configurations'
          ),
          byId: {
            _: pathCreator<
              WithReleaseOrDraftProcessId & WithScanConfigurationId
            >(
              '/processes/:releaseOrDraftProcessId/scan/configurations/:scanConfigurationId'
            ),
          },
        },
        instances: {
          _: pathCreator<WithReleaseOrDraftProcessId>(
            '/processes/:releaseOrDraftProcessId/scan/instances'
          ),
          byId: {
            _: pathCreator<WithReleaseOrDraftProcessId & WithScanInstanceId>(
              '/processes/:releaseOrDraftProcessId/scan/instances/:scanInstanceId'
            ),
            documents: {
              _: pathCreator<WithReleaseOrDraftProcessId & WithScanInstanceId>(
                '/processes/:releaseOrDraftProcessId/scan/instances/:scanInstanceId/documents'
              ),
              byId: {
                _: pathCreator<
                  WithReleaseOrDraftProcessId &
                    WithScanInstanceId &
                    WithScanDocContainerId
                >(
                  '/processes/:releaseOrDraftProcessId/scan/instances/:scanInstanceId/documents/:scanDocContainerId'
                ),
              },
              bulk: {
                _: pathCreator<
                  WithReleaseOrDraftProcessId & WithScanInstanceId
                >(
                  '/processes/:releaseOrDraftProcessId/scan/instances/:scanInstanceId/documents/bulk-update'
                ),
              },
            },
            pages: {
              _: pathCreator<WithReleaseOrDraftProcessId & WithScanInstanceId>(
                '/processes/:releaseOrDraftProcessId/scan/instances/:scanInstanceId/pages'
              ),
              preview: {
                _: pathCreator<
                  WithReleaseOrDraftProcessId & WithScanInstanceId
                >(
                  '/processes/:releaseOrDraftProcessId/scan/instances/:scanInstanceId/pages/preview'
                ),
              },
              byName: {
                _: pathCreator<
                  WithReleaseOrDraftProcessId & WithScanInstanceId & WithName
                >(
                  '/processes/:releaseOrDraftProcessId/scan/instances/:scanInstanceId/pages/:name'
                ),
                transform: {
                  _: pathCreator<
                    WithReleaseOrDraftProcessId & WithScanInstanceId & WithName
                  >(
                    '/processes/:releaseOrDraftProcessId/scan/instances/:scanInstanceId/pages/:name/transform'
                  ),
                },
              },
            },
            finish: {
              _: pathCreator<WithReleaseOrDraftProcessId & WithScanInstanceId>(
                '/processes/:releaseOrDraftProcessId/scan/instances/:scanInstanceId/finish'
              ),
            },
          },
        },
      },
      emailTemplates: {
        _: pathCreator<WithReleaseOrDraftProcessId>(
          '/processes/:releaseOrDraftProcessId/email-templates'
        ),
        byId: {
          _: pathCreator<WithReleaseOrDraftProcessId & WithEmailTemplateId>(
            '/processes/:releaseOrDraftProcessId/email-templates/:emailTemplateId'
          ),
          isUsed: {
            _: pathCreator<WithReleaseOrDraftProcessId & WithEmailTemplateId>(
              '/processes/:releaseOrDraftProcessId/email-templates/:emailTemplateId/is_used'
            ),
          },
        },
      },
      printRender: {
        _: pathCreator<WithReleaseOrDraftProcessId>(
          '/processes/:releaseOrDraftProcessId/print-render'
        ),
        scenarios: {
          _: pathCreator<WithReleaseOrDraftProcessId>(
            '/processes/:releaseOrDraftProcessId/print-render/scenarios'
          ),
        },
        byId: pathCreator<
          WithReleaseOrDraftProcessId & WithPrintRenderScenarioId
        >(
          '/processes/:releaseOrDraftProcessId/print-render/scenarios/:printRenderScenarioId'
        ),
        activate: {
          _: pathCreator<
            WithReleaseOrDraftProcessId & WithPrintRenderScenarioId
          >(
            '/processes/:releaseOrDraftProcessId/print-render/scenarios/:printRenderScenarioId/activate'
          ),
        },
        deActivate: {
          _: pathCreator<
            WithReleaseOrDraftProcessId & WithPrintRenderScenarioId
          >(
            '/processes/:releaseOrDraftProcessId/print-render/scenarios/:printRenderScenarioId/deactivate'
          ),
        },
      },
      export: {
        _: pathCreator<WithReleaseOrDraftProcessId>(
          '/processes/:releaseOrDraftProcessId/export'
        ),
      },
    },
    systemLogs: {
      _: pathCreator('/logs'),
    },
  },
  tenants: {
    _: pathCreator('tenants'),
    byId: {
      _: pathCreator<WithTenantId>('tenants/:tenantId'),
      users: {
        _: pathCreator<WithTenantId>('tenants/:tenantId/users'),
        byId: {
          _: pathCreator<WithTenantId & WithUserId>(
            'tenants/:tenantId/users/:userId'
          ),
        },
      },
    },
  },
  tmsConnections: {
    _: pathCreator('tms'),
    endpoints: {
      _: pathCreator('tms/endpoints'),
      keys: {
        _: pathCreator('tms/endpoints/keys'),
      },
    },
    byId: {
      _: pathCreator<WithTMSConnectionId>('tms/:tmsConnectionId'),
    },
    validateCredentials: {
      _: pathCreator('tms/validate-credentials'),
    },
  },
  ftpConnections: {
    _: pathCreator('ftp'),
    byId: {
      _: pathCreator<WithFTPConnectionId>('ftp/:ftpConnectionId'),
    },
    validateCredentials: {
      _: pathCreator('ftp/validate-credentials'),
    },
  },
  invitations: {
    _: pathCreator('/invitations'),
    byId: {
      _: pathCreator<WithInvitationId>('/invitations/:invitationId'),
      resend: {
        _: pathCreator<WithInvitationId>('/invitations/:invitationId/resend'),
      },
    },
  },
  replyTo: {
    _: pathCreator('/reply-to'),
  },
  licenses: {
    _: pathCreator('/licenses'),
  },
  searchTemplate: {
    _: pathCreator('/search-templates'),
    byId: {
      _: pathCreator<WithSearchTemplateId>(
        '/search-templates/:searchTemplateId'
      ),
    },
  },
  external: {
    form: {
      _: pathCreator('/external-form'),
      byId: {
        _: pathCreator<WithExternalFormId>('/external-form/:externalFormId'),
        resend: {
          _: pathCreator<WithExternalFormId>(
            '/external-form/:externalFormId/resend'
          ),
        },
      },
      validate: {
        _: pathCreator('/external-form/validate'),
      },
      submit: {
        _: pathCreator('/external-form/submit'),
      },
    },
  },
  emailprovider: {
    _: pathCreator('/imap'),
    validateCredentials: {
      _: pathCreator('/imap/validate-credentials'),
    },
    getLabels: {
      _: pathCreator('/imap/get-labels'),
    },
  },
  inboundEmail: {
    _: pathCreator('/inbound-email'),
  },
  printRender: {
    _: pathCreator('/print-render'),
    file: {
      _: pathCreator('/print-render/file'),
    },
  },
};
