import { combineReducers } from 'redux';

import { API_REQ_ACTION_TYPES } from 'api/actions';
import { ProcessDetails, ProcessGeneral } from 'api/endpoints/processes';
import { FieldGeneral, GetFieldsByIdResult } from 'api/endpoints/processFields';
import { ProcessInstanceDetails } from 'api/endpoints/processInstance';
import { ProcessVersionGeneral } from 'api/endpoints/processVersions';

import { SystemStatus } from 'constants/global';

import { PROCESS_ACTION_TYPES } from './actions';

export interface ProcessState {
  list: Nullable<ProcessGeneral[]>;
  total: Nullable<number>;
  details: Nullable<ProcessDetails>;
  processInstance: Nullable<ProcessInstanceDetails>;
  activeVersion: Nullable<ProcessVersionGeneral>;
  instanceFieldsList: Nullable<FieldGeneral[]>;
  instanceFieldsTotal: Nullable<number>;
}

const process = combineReducers<ProcessState>({
  list(
    state = null,
    { type, payload }: { type: string; payload: DataWithTotal<ProcessGeneral> }
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.PROCESSES.GET_ALL.SUCCESS:
        return payload ? payload.data : null;
      default:
        return state;
    }
  },
  total(
    state = null,
    { type, payload }: { type: string; payload: DataWithTotal<ProcessGeneral> }
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.PROCESSES.GET_ALL.SUCCESS:
        return payload ? payload.total : null;
      default:
        return state;
    }
  },
  details(
    state = null,
    {
      type,
      payload,
      meta,
    }: {
      type: string;
      payload: ProcessDetails;
      meta: { localFetching?: boolean };
    }
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.PROCESSES.GET_BY_ID.SUCCESS:
        return meta.localFetching ? state : payload;

      case PROCESS_ACTION_TYPES.CLEAR_DETAILS:
      case API_REQ_ACTION_TYPES.PROCESSES.VERSIONS.GET_DETAILS.SUCCESS:
      case API_REQ_ACTION_TYPES.PROCESSES.GET_ALL.START:
        return null;

      default:
        return state;
    }
  },
  activeVersion(
    state = null,
    {
      type,
      payload,
      meta,
    }: {
      type: string;
      payload: ProcessDetails;
      meta: { localFetching?: boolean };
    }
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.PROCESSES.VERSIONS.CREATE.SUCCESS:
        return payload as unknown as ProcessVersionGeneral;
      case API_REQ_ACTION_TYPES.PROCESSES.GET_BY_ID.SUCCESS:
        return meta.localFetching
          ? state
          : (payload?.version ??
              payload.versions?.find((e) => e.status === SystemStatus.Active) ??
              null);

      case PROCESS_ACTION_TYPES.CLEAR_ACTIVE_VERSION:
      case API_REQ_ACTION_TYPES.PROCESSES.GET_ALL.START:
        return null;

      default:
        return state;
    }
  },

  processInstance(
    state = null,
    { type, payload }: { type: string; payload: ProcessInstanceDetails }
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.INSTANCE.GET_INSTANCE_BY_ID.SUCCESS:
      case API_REQ_ACTION_TYPES.INSTANCE.RE_DO_STEP.SUCCESS:
        return payload;

      case API_REQ_ACTION_TYPES.INSTANCE.GET_INSTANCE_BY_ID.START:
        return null;

      default:
        return state;
    }
  },
  instanceFieldsList(
    state = null,
    { type, payload }: { type: string; payload: GetFieldsByIdResult }
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.FIELDS.GET_BY_PROCESS_ID.SUCCESS:
        return payload.data;

      default:
        return state;
    }
  },
  instanceFieldsTotal(
    state = null,
    { type, payload }: { type: string; payload: GetFieldsByIdResult }
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.FIELDS.GET_BY_PROCESS_ID.SUCCESS:
        return payload.total;

      default:
        return state;
    }
  },
});

export default process;
