import ROUTES from 'app/routes/paths';
import { UiWithReleaseOrDraftProcessId } from 'app/routes/uiPathParams';
import { getLocation } from 'connected-react-router';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { setSubmitSucceeded } from 'redux-form';
import { fork, put, select } from 'redux-saga/effects';

import fetchFields from 'store/field/sagas/fetchFields';

import { FORM_NAMES } from 'constants/form';

function* reFetchModelForms() {
  const { query, pathname } = yield select(getLocation);

  const {
    params: { releaseOrDraftProcessId },
  }: match<UiWithReleaseOrDraftProcessId> = yield matchPath(pathname, {
    path: [ROUTES.process.definition.details.fields()],
  });

  const body = { releaseOrDraftProcessId, ...query };

  yield fork(fetchFields, body);

  yield put(setSubmitSucceeded(FORM_NAMES.ADD_DATA_MODEL_FIELD));
}

export default reFetchModelForms;
