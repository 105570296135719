import { ApiPaths } from '../../../api/constants';
import {
  GetListResult,
  GridTemplateGeneral,
} from '../../../api/endpoints/grid';
import { ProcessGeneral } from '../../../api/endpoints/processes';
import {
  CreateParams,
  SearchTemplateGeneral,
} from '../../../api/endpoints/searchTemplate';
import BaseRepo from '../../BaseRepo';
import { ApiResponseAsync, isApiError } from '../../models';
import { IGridRepo } from './IGridRepo';

export class GridRepo extends BaseRepo implements IGridRepo {
  getGridActiveTemplate = (
    draft: ProcessGeneral
  ): ApiResponseAsync<Nullable<GridTemplateGeneral>> => {
    const { REACT_APP_API_BASE: baseURL } = process.env;
    const uri = new URL(baseURL + ApiPaths.gridTemplates._());
    uri.searchParams.append('type', 'instance_list');
    uri.searchParams.append('process_id', draft.id);

    return this.helpers
      .handleGet<GetListResult>(uri.toString())
      .then((searchResult) => {
        if (isApiError(searchResult)) {
          return searchResult;
        }
        if (searchResult.data === null) {
          return null;
        }
        return (
          searchResult.data.find(
            (gridTemplate) => gridTemplate.status === 'active'
          ) ?? null
        );
      });
  };

  saveToMyTask = (
    draft: ProcessGeneral,
    name: string,
    url: URL
  ): ApiResponseAsync<SearchTemplateGeneral> => {
    const params: CreateParams = {
      url: url.href,
      name,
      processId: draft.id,
    };

    return this.helpers.handlePost<CreateParams, SearchTemplateGeneral>(
      ApiPaths.searchTemplate._(),
      params
    );
  };
}
