import { call, put } from 'redux-saga/effects';

import { ApiSagaResult } from 'api/apiReqSagaCreator';
import ProcessForm, {
  FieldWithObligationType,
  GetFormByIdParams,
  GetFormByIdResult,
} from 'api/endpoints/processForm';

import { ProcessForm as ProcessFormActions } from '../actions';

export type SetFormData = [GetFormByIdParams];

function* setFormData(params: GetFormByIdParams) {
  const { result: formFieldsResult }: ApiSagaResult<GetFormByIdResult> =
    yield call(ProcessForm.getFormById, params);

  const updatedFields: FieldWithObligationType[] =
    (formFieldsResult as GetFormByIdResult)?.fields || [];

  const formDetails = {
    ...(formFieldsResult as GetFormByIdResult),
    fields: updatedFields,
  };

  yield put(ProcessFormActions.setProcessFormDetails(formDetails));

  return formDetails;
}

export default setFormData;
