import { ProcessGeneral } from '../../../api/endpoints/processes';
import { ProcessAlias } from '../../../api/endpoints/processFields';
import { SHOULD_ADD_INDEX_WHEN_SETTING_A_OR_Q_SEARCH } from '../../../pages/processDefinitionDataModelFields/config';
import BaseRepo from '../../BaseRepo';
import {
  buildBadRequestApiError,
  buildForbiddenApiError,
} from '../../helpers/errors';
import { ApiResponseAsync } from '../../models';
import { PatchTyped } from '../../patch';
import { SearchRequestTyped } from '../../search';
import {
  CreateFieldAliasRequest,
  IFieldAliasesRepo,
  UpdateAliasRequestType,
} from './IFieldAliases';

export class FieldAliasesRepo extends BaseRepo implements IFieldAliasesRepo {
  create = (
    draft: ProcessGeneral,
    request: CreateFieldAliasRequest
  ): ApiResponseAsync<ProcessAlias> => {
    const link = draft.links ? draft.links['createFieldAlias'] : undefined;
    if (!link) {
      return Promise.resolve(
        buildBadRequestApiError(
          new Error('link "createFieldAlias" is not available')
        )
      );
    }
    return this.helpers.handlePost<CreateFieldAliasRequest, ProcessAlias>(
      link.href,
      request
    );
  };

  search = (
    draft: ProcessGeneral,
    request: SearchRequestTyped<ProcessAlias>
  ): ApiResponseAsync<ProcessAlias[]> => {
    const link = draft.links ? draft.links['searchFieldAliases'] : undefined;
    if (!link) {
      return Promise.resolve(
        buildBadRequestApiError(
          new Error('link "search Field Aliases" is not available')
        )
      );
    }

    return this.helpers.executeSearch(link, request);
  };

  deleteField = (field: ProcessAlias): ApiResponseAsync<void> => {
    const link = field.links ? field.links['delete'] : undefined;
    if (!link) {
      return Promise.resolve(
        buildForbiddenApiError(new Error('delete field alias is not allowed'))
      );
    }
    return this.helpers.handleDelete(link.href);
  };

  update = (
    field: ProcessAlias,
    request: UpdateAliasRequestType
  ): ApiResponseAsync<ProcessAlias> => {
    const link = field.links ? field.links['update'] : undefined;
    if (!link) {
      return Promise.resolve(
        buildForbiddenApiError(
          new Error(`updating field's alias is not allowed`)
        )
      );
    }
    return this.helpers.executePatch(link, request);
  };

  setForAdvancedSearch = (
    field: ProcessAlias,
    enable: boolean
  ): ApiResponseAsync<ProcessAlias> => {
    const link = field.links ? field.links['setAdvancedSearch'] : undefined;
    if (!link) {
      return Promise.resolve(
        buildForbiddenApiError(
          new Error(`updating field's advanced search setting is not allowed`)
        )
      );
    }
    if (field.isForAdvancedSearch === enable) {
      return Promise.resolve(field);
    }
    const patch: PatchTyped<ProcessAlias> = {
      isForAdvancedSearch: enable,
    };
    if (enable && SHOULD_ADD_INDEX_WHEN_SETTING_A_OR_Q_SEARCH(field)) {
      patch['isForIndex'] = enable;
    }
    return this.helpers.executePatch(link, patch);
  };

  setForIndex = (
    field: ProcessAlias,
    enable: boolean
  ): ApiResponseAsync<ProcessAlias> => {
    const link = field.links ? field.links['setIsIndexed'] : undefined;
    if (!link) {
      return Promise.resolve(
        buildForbiddenApiError(
          new Error(`updating field's index setting is not allowed`)
        )
      );
    }
    if (field.isForIndex === enable) {
      return Promise.resolve(field);
    }

    const patch: PatchTyped<ProcessAlias> = {
      isForIndex: enable,
    };
    return this.helpers.executePatch(link, patch);
  };

  setForQuickSearch = (
    field: ProcessAlias,
    enable: boolean
  ): ApiResponseAsync<ProcessAlias> => {
    const link = field.links ? field.links['setQuickSearch'] : undefined;
    if (!link) {
      return Promise.resolve(
        buildForbiddenApiError(
          new Error(`updating field's quick search setting is not allowed`)
        )
      );
    }
    if (field.isForQuickSearch === enable) {
      return Promise.resolve(field);
    }

    const patch: PatchTyped<ProcessAlias> = {
      isForQuickSearch: enable,
    };

    if (enable && SHOULD_ADD_INDEX_WHEN_SETTING_A_OR_Q_SEARCH(field)) {
      patch['isForIndex'] = enable;
    }
    return this.helpers.executePatch(link, patch);
  };

  setForView = (
    field: ProcessAlias,
    enable: boolean
  ): ApiResponseAsync<ProcessAlias> => {
    const link = field.links ? field.links['setVisible'] : undefined;
    if (!link) {
      return Promise.resolve(
        buildForbiddenApiError(
          new Error(`updating field's view setting is not allowed`)
        )
      );
    }
    if (field.isForView === enable) {
      return Promise.resolve(field);
    }

    const patch: PatchTyped<ProcessAlias> = {
      isForView: enable,
    };
    return this.helpers.executePatch(link, patch);
  };

  /**
   * Sets a process alias as either "removed" or "restores". Removed means the field will not be present in
   * configurations and all indexes with be deleted. Restored means the field will be present in configurations.
   * @param field the process alias to set as removed or restored
   * @param shouldRemove whether to remove the field, otherwise restore
   */
  setAsRemoved = (
    field: ProcessAlias,
    shouldRemove: boolean
  ): ApiResponseAsync<ProcessAlias> => {
    const link = field.links ? field.links['setRemoved'] : undefined;
    if (!link) {
      return Promise.resolve(
        buildForbiddenApiError(
          new Error(
            `${shouldRemove ? 'removing' : 'restoring'} the field is not allowed`
          )
        )
      );
    }
    if (field.isRemoved === shouldRemove) {
      return Promise.resolve(field);
    }

    const patch: PatchTyped<ProcessAlias> = {
      isRemoved: shouldRemove,
    };

    return this.helpers.executePatch(link, patch);
  };
}
