import useClickOutside, { ListenerEvent } from 'hooks/useClickOutside';
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { KEY } from 'components/organisms/select/config';

import { OnSelectChange, Props } from '.';

type ReusedProps =
  | 'list'
  | 'isReadOnly'
  | 'onClick'
  | 'isDisabled'
  | 'onSelectChange'
  | 'onSelectBlur'
  | 'onListToggle'
  | 'selected';

type ResetSearchAndOpenState = EmptyArgsVoidFunc;
type ClickHandler = EmptyArgsVoidFunc;
type ChangeHandler = OnSelectChange;
type HandleSearchChange = (e: ChangeEvent<HTMLInputElement>) => void;
const useSelect = ({
  list,
  selected,
  onSelectBlur,
  onSelectChange,
  onListToggle,
  onClick,
  isReadOnly,
  isDisabled,
}: Pick<Props, ReusedProps>) => {
  // region *******************************DATA*********************************
  const [searchValue, setSearchValue] = useState<string | undefined>();

  const [isOpen, setIsOpen] = useState(false);

  const wrapperRef = useRef<Nullable<HTMLDivElement>>(null);

  const localList: SelectItem[] = useMemo(
    () =>
      (list || []).filter((e) =>
        e.label.toLowerCase().includes((searchValue || '').toLowerCase())
      ),
    [list, searchValue]
  );

  const labelToRender = useMemo(() => {
    if (typeof searchValue !== 'undefined') return searchValue;

    if (typeof selected === 'string') return selected;

    if (typeof selected?.label === 'string' && selected?.label.includes(KEY)) {
      return (list || []).find((e) => e.value === selected.value)?.label || '';
    }

    return selected?.label;
  }, [list, searchValue, selected]);
  // endregion

  // region *****************************CALLBACKS******************************
  const resetSearchAndOpenState: ResetSearchAndOpenState = useCallback(() => {
    setSearchValue(undefined);
    setIsOpen(false);
  }, []);

  const clickHandler: ClickHandler = useCallback(() => {
    if (isReadOnly || isDisabled) return;

    if (onClick) onClick();

    setSearchValue(undefined);
    setIsOpen(!isOpen);
  }, [isDisabled, isOpen, isReadOnly, onClick]);

  const changeHandler: ChangeHandler = useCallback(
    (value) => {
      if (onSelectChange) {
        onSelectChange(value);
      }

      resetSearchAndOpenState();
    },
    [onSelectChange, resetSearchAndOpenState]
  );

  const handleClickOutside: (e: ListenerEvent) => void = useCallback(
    (e) => {
      if (onSelectBlur) {
        onSelectBlur(e, selected);
      }
      resetSearchAndOpenState();
    },
    [onSelectBlur, resetSearchAndOpenState, selected]
  );

  const handleSearchChange: HandleSearchChange = useCallback(
    (e) => {
      if (!isOpen) setIsOpen(true);
      setSearchValue(e.target.value);
    },
    [isOpen]
  );
  // endregion

  // region ******************************EFFECTS*******************************
  useClickOutside(wrapperRef, isOpen ? handleClickOutside : undefined);

  useEffect(() => {
    if (onListToggle) onListToggle(isOpen);
  }, [isOpen, onListToggle]);
  // endregion

  return useMemo(
    () => ({
      localList,
      clickHandler,
      handleSearchChange,
      changeHandler,
      wrapperRef,
      isOpen,
      searchValue,
      labelToRender,
    }),
    [
      changeHandler,
      clickHandler,
      handleSearchChange,
      isOpen,
      labelToRender,
      localList,
      searchValue,
    ]
  );
};

export default useSelect;
