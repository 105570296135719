import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import snakeCase from 'to-snake-case';

import {
  transformRequest,
  transformResponse,
} from '../api/apiReqSagaCreator/httpRequest';
import { authCache } from '../api/cache';
import { CONTENT_TYPE } from '../api/constants';
import { JsonPatch } from '../api/types/jsonPatch';
import { OdataURLBuilder } from '../api/types/OdataURLBuilder';
import { buildUnauthorizedApiError } from './helpers/errors';
import { ApiResponseAsync, translateAxiosErrorToApiError } from './models';
import { convertSearchToOData } from './odata';
import { Patch, patchToJSONPatch } from './patch';
import { SearchRequest } from './search';
import { ServiceContext } from './serviceFactory';

function handleGet<Response>(
  url: string,
  client: AxiosInstance,
  accessToken: string,
  config?: {
    responseStopTransformPaths?: string[];
  }
): Promise<Response> {
  const axiosConfig: AxiosRequestConfig<Response> = {
    headers: {
      'Access-Token': accessToken,
    },
  };
  if (config?.responseStopTransformPaths) {
    axiosConfig.transformResponse = (data) =>
      transformResponse(data, config.responseStopTransformPaths);
  }
  return client
    .get<Response>(url, axiosConfig)
    .then((response) => response.data);
}

function handlePatch<Payload, Response>(
  url: string,
  data: Payload,
  client: AxiosInstance,
  accessToken: string
): Promise<Response> {
  return client
    .patch<Response, AxiosResponse<Response>, Payload>(url, data, {
      headers: {
        'Access-Token': accessToken,
      },
    })
    .then((response) => response.data);
}

function handleDelete(
  url: string,
  client: AxiosInstance,
  accessToken: string,
  config?: {
    baseUrl?: string;
  }
): Promise<void> {
  const axiosConfig: AxiosRequestConfig = {
    headers: {
      'Access-Token': accessToken,
    },
  };
  if (config?.baseUrl !== undefined && config?.baseUrl !== '') {
    axiosConfig.baseURL = config.baseUrl;
  }
  return client.delete<void>(url, axiosConfig).then(() => Promise.resolve());
}

function handlePost<Payload, Response>(
  url: string,
  data: Payload,
  client: AxiosInstance,
  accessToken: string,
  config?: {
    requestStopTransformPaths?: string[];
    responseStopTransformPaths?: string[];
    baseUrl?: string;
  }
): Promise<Response> {
  const axiosConfig: AxiosRequestConfig<Payload> = {
    headers: {
      'Access-Token': accessToken,
    },
  };
  let applyRequestTransformation = true;
  if (data instanceof FormData) {
    axiosConfig.headers!['Content-Type'] = CONTENT_TYPE.MULTIPART_FORMDATA;
    applyRequestTransformation = false;
  }
  if (config?.baseUrl) {
    axiosConfig.baseURL = config.baseUrl;
  }
  if (config?.requestStopTransformPaths && applyRequestTransformation) {
    axiosConfig.transformRequest = (data) =>
      transformRequest(data, config.requestStopTransformPaths);
  }
  if (config?.responseStopTransformPaths) {
    axiosConfig.transformResponse = (data) =>
      transformResponse(data, config.responseStopTransformPaths);
  }
  return client
    .post<Response, AxiosResponse<Response>, Payload>(url, data, axiosConfig)
    .then((response) => response.data);
}

function handlePostAction<Payload>(
  url: string,
  data: Payload,
  client: AxiosInstance,
  accessToken: string,
  config?: {
    requestStopTransformPaths?: string[];
    baseUrl?: string;
  }
): Promise<void> {
  return handlePost<Payload, void>(url, data, client, accessToken, config);
}

function handlePut<Payload, Response>(
  url: string,
  data: Payload,
  client: AxiosInstance,
  accessToken: string,
  config?: {
    requestStopTransformPaths?: string[];
  }
): Promise<Response> {
  const axiosConfig: AxiosRequestConfig<Payload> = {
    headers: {
      'Access-Token': accessToken,
    },
  };
  if (config?.requestStopTransformPaths) {
    axiosConfig.transformRequest = (data) =>
      transformRequest(data, config.requestStopTransformPaths);
  }

  return client
    .put<Response, AxiosResponse<Response>, Payload>(url, data, axiosConfig)
    .then((response) => response.data);
}

function executeSearch<Response>(
  link: Link,
  request: SearchRequest,
  client: AxiosInstance,
  accessToken: string,
  config?: {
    responseStopTransformPaths?: string[];
  }
): Promise<Response> {
  const builder = new OdataURLBuilder(link);
  const odata = convertSearchToOData(request);
  if (odata.filter) {
    builder.setFilter(odata.filter);
  }
  if (odata.top) {
    builder.setTop(odata.top);
  }
  if (odata.skip) {
    builder.setSkip(odata.skip);
  }
  if (odata.orderBy) {
    builder.setOrderBy(odata.orderBy);
  }
  if (odata.count) {
    builder.setCount(true);
  }

  return handleGet<Response>(builder.toString(), client, accessToken, config);
}

function executePatch<Response>(
  link: Link,
  patch: Patch,
  client: AxiosInstance,
  accessToken: string
): Promise<Response> {
  const patchItems = patchToJSONPatch(patch);
  return handlePatch<JsonPatch[], Response>(
    link.href,
    patchItems,
    client,
    accessToken
  );
}

function with401Retry<Response>(
  context: ServiceContext,
  request: (token: string) => Promise<Response>
): ApiResponseAsync<Response> {
  return request(context.tokenStateAccessor().accessToken).catch((error) => {
    const axiosError = error as AxiosError;
    if (!axiosError.response || axiosError.response.status !== 401) {
      return translateAxiosErrorToApiError(error);
    }

    return authCache.refreshTokens().then(
      () =>
        request(authCache.authToken).catch((error) =>
          translateAxiosErrorToApiError(error)
        ),
      (err) => {
        authCache.clear();
        console.error('refresh token failed', err);
        throw buildUnauthorizedApiError();
      }
    );
  });
}

export interface RepoAxiosHelpers {
  handleGet: <Response>(
    url: string,
    config?: {
      responseStopTransformPaths?: string[];
    }
  ) => ApiResponseAsync<Response>;
  handlePost: <Payload, Response>(
    url: string,
    data: Payload,
    config?: {
      requestStopTransformPaths?: string[];
      responseStopTransformPaths?: string[];
      baseUrl?: string;
    }
  ) => ApiResponseAsync<Response>;
  handlePostAction: <Payload>(
    url: string,
    data: Payload,
    config?: {
      requestStopTransformPaths?: string[];
      baseUrl?: string;
    }
  ) => ApiResponseAsync<void>;
  handlePatch: <Payload, Response>(
    url: string,
    data: Payload
  ) => ApiResponseAsync<Response>;
  handleDelete: (
    url: string,
    config?: { baseUrl?: string }
  ) => ApiResponseAsync<void>;
  handlePut: <Payload, Response>(
    url: string,
    data: Payload,
    config?: {
      requestStopTransformPaths?: string[];
    }
  ) => ApiResponseAsync<Response>;
  executeSearch: <Response>(
    link: Link,
    request: SearchRequest,
    config?: { responseStopTransformPaths?: string[] }
  ) => ApiResponseAsync<Response>;
  executePatch<Response>(
    link: Link,
    patch: Patch,
    convertToSnakeCase?: boolean
  ): ApiResponseAsync<Response>;
}

/**
 * BaseRepo handles the boilerplate of setting up a repository, specifically the
 * axios client and the token accessor.
 *
 * Instead of being fully private, which means that no class *except* BaseRepo
 * can access it, we use public getters to restrict access only to BaseRepo and
 * subclass instances.
 *
 * Add it at the top of the inheritance chain, like so:
 * ```ts
 *   abstract class AbstractFooRepo extends BaseRepo {}
 *   class FooRepo extends AbstractFooRepo {}
 * ```
 */
export default abstract class BaseRepo {
  private readonly _client: AxiosInstance;
  private readonly _helpers: RepoAxiosHelpers;
  private readonly _context: ServiceContext;
  constructor(client: AxiosInstance, context: ServiceContext) {
    this._client = client;
    this._context = context;
    this._helpers = {
      handleGet: <Response>(
        url: string,
        config?: {
          responseStopTransformPaths?: string[];
        }
      ) =>
        with401Retry<Response>(this._context, (accessToken: string) =>
          handleGet<Response>(url, this._client, accessToken, config)
        ),
      handlePost: <Payload, Response>(
        url: string,
        data: Payload,
        config?: {
          requestStopTransformPaths?: string[];
          responseStopTransformPaths?: string[];
          baseUrl?: string;
        }
      ) =>
        with401Retry<Response>(this._context, (accessToken: string) =>
          handlePost<Payload, Response>(
            url,
            data,
            this._client,
            accessToken,
            config
          )
        ),
      handlePostAction: <Payload>(
        url: string,
        data: Payload,
        config?: {
          requestStopTransformPaths?: string[];
          baseUrl?: string;
        }
      ) =>
        with401Retry<void>(this._context, (accessToken: string) =>
          handlePostAction<Payload>(
            url,
            data,
            this._client,
            accessToken,
            config
          )
        ),
      handlePut: <Payload, Response>(
        url: string,
        data: Payload,
        config?: { requestStopTransformPaths?: string[] }
      ) =>
        with401Retry<Response>(this._context, (accessToken: string) =>
          handlePut<Payload, Response>(
            url,
            data,
            this._client,
            accessToken,
            config
          )
        ),
      handlePatch: <Payload, Response>(url: string, data: Payload) =>
        with401Retry<Response>(this._context, (accessToken: string) =>
          handlePatch<Payload, Response>(url, data, this._client, accessToken)
        ),
      handleDelete: (url: string, config?: { baseUrl?: string }) =>
        with401Retry<void>(this._context, (accessToken: string) =>
          handleDelete(url, this._client, accessToken, config)
        ),
      executeSearch: <Response>(
        link: Link,
        request: SearchRequest,
        config: { responseStopTransformPaths?: string[] } | undefined
      ) =>
        with401Retry<Response>(this._context, (accessToken: string) =>
          executeSearch<Response>(
            link,
            request,
            this._client,
            accessToken,
            config
          )
        ),
      executePatch: <Response>(
        link: Link,
        patch: Patch,
        convertToSnakeCase: boolean = true
      ) => {
        let finalPatch: Patch = {};
        if (convertToSnakeCase) {
          Object.entries(patch).forEach(([fieldName, value]) => {
            finalPatch[snakeCase(fieldName)] = value;
          });
        } else {
          finalPatch = patch;
        }
        return with401Retry<Response>(this._context, (accessToken: string) =>
          executePatch<Response>(link, finalPatch, this._client, accessToken)
        );
      },
    };
  }

  get client(): AxiosInstance {
    if (this instanceof BaseRepo) return this._client;
    throw new TypeError(`property 'client' is protected`);
  }

  get tokenAccessor(): () => string {
    if (this instanceof BaseRepo)
      return () => this._context.tokenStateAccessor().accessToken;
    throw new TypeError(`property 'tokenAccessor' is protected`);
  }

  get helpers(): RepoAxiosHelpers {
    if (this instanceof BaseRepo) return this._helpers;
    throw new TypeError(`property 'helpers' is protected`);
  }
}
