import BaseRepo from 'container/BaseRepo';
import { translateAxiosErrorToApiError } from 'container/models';

import { IUserRepo } from './IUserRepo';

export class UserRepo extends BaseRepo implements IUserRepo {
  resetPassword(username: string) {
    return this.client
      .post<void>(`/auth/forgot-password`, { username })
      .then((res) => res.data)
      .catch(translateAxiosErrorToApiError);
  }
}
