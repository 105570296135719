import { transformResponse } from 'api/apiReqSagaCreator/httpRequest';
import { ProcessGeneral } from 'api/endpoints/processes';
import { FieldGeneral } from 'api/endpoints/processFields';
import { FormGeneral } from 'api/endpoints/processForm';
import { ProcessVersionDetails } from 'api/endpoints/processVersions';
import {
  WithProcessInstanceId,
  WithReleaseOrDraftProcessId,
  WithStepId,
} from 'api/types/pathParams';

import ApiUrlParams from 'constants/apiUrlParams';

import { API_REQ_ACTION_TYPES } from '../actions';
import apiReqSagaCreator from '../apiReqSagaCreator';
import { ApiPaths, HTTP_METHODS } from '../constants';

export type ProcessInstanceDataModel = DynamicFormNormalizedParams;

export interface ProcessInstanceToCreate {
  name?: string;
  variables?: ProcessInstanceDataModel;
}

export type ProcessInstanceStatus = 'running' | 'success' | 'failed';

export interface ProcessInstancePreview extends BaseEntity {
  status: ProcessInstanceStatus;
  process: ProcessGeneral;
}

export interface ProcessInstanceCurrentStage
  extends Pick<BaseEntity, 'name' | 'id'> {
  index: string;
}

export interface ProcessInstanceGeneral
  extends Omit<ProcessInstancePreview, 'process'>,
    WithHypermedia {
  folderUrl: string;
  currentStage: ProcessInstanceCurrentStage;
  variables?: ProcessInstanceDataModel;
  version: ProcessVersionDetails;
}

export interface Task extends Omit<BaseEntity, 'name'> {
  type: string;
  params: KeyValuePairs;
  result: string;
  status: string;
  formToGet?: FormGeneral;
  indexField?: FieldGeneral;
}

export interface TaskWrap {
  subTasks?: Task[];
  task: Task;
}

export interface ProcessInstanceStageStepBase
  extends ProcessInstanceCurrentStage {
  status: string;
}

export interface Step extends ProcessInstanceStageStepBase {
  tasks?: TaskWrap[];
}

export interface ExecutionStageGeneral extends ProcessInstanceStageStepBase {
  steps: Step[];
}

export interface ProcessInstanceMessage {
  type: 'info' | 'warning' | 'error';
  user?: string;
  stageId?: string;
  stepId?: string;
  stepName?: string;
  message: string;
  createdAt: string;
}

export interface ProcessInstanceDetails extends BaseEntity, WithHypermedia {
  status: ProcessInstanceStatus;
  dataModel: Nullable<{
    messages: ProcessInstanceMessage[];
    variables: ProcessInstanceDataModel;
  }>;
  stages: ExecutionStageGeneral[];
  version: ProcessVersionDetails;
}

export interface CreateProcessInstanceData {
  instances: ProcessInstanceToCreate[];
}

export interface UpdateFieldsParams {
  fields: ProcessInstanceDataModel;
}

export interface UpdateFileFieldValue {
  url: string;
  value: FormData;
}

export interface WithStatus {
  status: ProcessInstanceStatus;
}

export interface GetInstanceById {
  status?: ProcessInstanceStatus;
  search?: SearchParams;
}

export interface InstanceCountData {
  all: number;
  success: number;
  failed: number;
  running: number;
}

export type CreateProcessInstanceParams = CreateProcessInstanceData &
  WithReleaseOrDraftProcessId;
export type CreateProcessInstanceResult = ProcessInstancePreview[];
export type GetProcessInstanceByIdParams = WithReleaseOrDraftProcessId &
  WithProcessInstanceId;
export type GetProcessInstanceByIdResult = ProcessInstanceDetails;
export type DeleteProcessInstanceByIdParams = WithReleaseOrDraftProcessId &
  WithProcessInstanceId;
export type DeleteProcessInstanceByIdResult = unknown;
export type GetInstancesListParams = Pagination &
  Sort &
  GetInstanceById &
  WithReleaseOrDraftProcessId;

export interface GetInstancesListResult {
  data: ProcessInstanceGeneral[];
  total: InstanceCountData;
}

export type FinishProcessInstanceParams = WithReleaseOrDraftProcessId &
  WithProcessInstanceId &
  WithStatus;
export type FinishProcessInstanceResult = unknown;
export type NextProcessInstanceTaskParams = UpdateFieldsParams &
  WithReleaseOrDraftProcessId &
  WithProcessInstanceId;
export type NextProcessInstanceTaskResult = ProcessInstanceDetails;
export type SkipProcessInstanceTaskParams = WithReleaseOrDraftProcessId &
  WithProcessInstanceId;
export type SkipProcessInstanceTaskResult = ProcessInstanceDetails;
export type UpdateInstanceFieldsParams = UpdateFieldsParams &
  WithReleaseOrDraftProcessId &
  WithProcessInstanceId;
export type UpdateInstanceFieldsResult = ProcessInstanceDetails;
export type UpdateFileFieldValueParams = UpdateFileFieldValue;
export type UpdateFileFieldValueResult = unknown;
export type ReDoStepParams = WithReleaseOrDraftProcessId &
  WithProcessInstanceId &
  WithStepId;
export type ReDoStepResult = ProcessInstanceDetails;

const mapperPaths = [
  'stages.steps.tasks.task.params.mapper',
  'stages.steps.tasks.sub_tasks.params.mapper',
];

const ProcessInstanceEndpoint = {
  getProcessInstanceById: apiReqSagaCreator<
    GetProcessInstanceByIdParams,
    GetProcessInstanceByIdResult
  >(API_REQ_ACTION_TYPES.INSTANCE.GET_INSTANCE_BY_ID, (params) => ({
    method: HTTP_METHODS.GET,
    transformResponse: (data) => transformResponse(data, mapperPaths),
    url: ApiPaths.processes.byId.instances.byId._(params),
  })),
  finishProcessInstance: apiReqSagaCreator<
    FinishProcessInstanceParams,
    FinishProcessInstanceResult
  >(API_REQ_ACTION_TYPES.INSTANCE.FINISH_INSTANCE, (args) => ({
    method: HTTP_METHODS.POST,
    url: ApiPaths.processes.byId.instances.byId.finish._({
      [ApiUrlParams.releaseOrDraftProcessId]:
        args[ApiUrlParams.releaseOrDraftProcessId],
      [ApiUrlParams.processInstanceId]: args[ApiUrlParams.processInstanceId],
    }),
    data: { status: args.status },
  })),
  nextProcessInstanceTask: apiReqSagaCreator<
    NextProcessInstanceTaskParams,
    NextProcessInstanceTaskResult
  >(API_REQ_ACTION_TYPES.INSTANCE.NEXT_INSTANCE_TASK, (args) => ({
    method: HTTP_METHODS.POST,
    url: ApiPaths.processes.byId.instances.byId.continue._({
      [ApiUrlParams.releaseOrDraftProcessId]:
        args[ApiUrlParams.releaseOrDraftProcessId],
      [ApiUrlParams.processInstanceId]: args[ApiUrlParams.processInstanceId],
    }),
    data: { params: args.fields },
  })),
  skipProcessInstanceTask: apiReqSagaCreator<
    SkipProcessInstanceTaskParams,
    SkipProcessInstanceTaskResult
  >(API_REQ_ACTION_TYPES.INSTANCE.SKIP_INSTANCE_TASK, (params) => ({
    method: HTTP_METHODS.POST,
    url: ApiPaths.processes.byId.instances.byId.skip._(params),
  })),
  updateInstanceFields: apiReqSagaCreator<
    UpdateInstanceFieldsParams,
    UpdateInstanceFieldsResult
  >(API_REQ_ACTION_TYPES.INSTANCE.UPDATE_INSTANCE, (args) => ({
    method: HTTP_METHODS.POST,
    url: ApiPaths.processes.byId.instances.byId.update._({
      [ApiUrlParams.releaseOrDraftProcessId]:
        args[ApiUrlParams.releaseOrDraftProcessId],
      [ApiUrlParams.processInstanceId]: args[ApiUrlParams.processInstanceId],
    }),
    data: { params: args.fields },
  })),
  updateFileFieldValue: apiReqSagaCreator<
    UpdateFileFieldValueParams,
    UpdateFileFieldValueResult
  >(API_REQ_ACTION_TYPES.INSTANCE.UPDATE_FILE_FIELD_VALUE, (args) => ({
    method: HTTP_METHODS.PUT,
    url: args.url,
    data: args.value,
  })),
  reDoStep: apiReqSagaCreator<ReDoStepParams, ReDoStepResult>(
    API_REQ_ACTION_TYPES.INSTANCE.RE_DO_STEP,
    (params) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.processes.byId.instances.byId.steps.byId.reDo._(params),
      transformResponse: (data) => transformResponse(data, mapperPaths),
    })
  ),
};

export default ProcessInstanceEndpoint;
