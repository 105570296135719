import {
  EmailPolling,
  EmailPollingDetails,
  FtpPolling,
  FtpPollingDetails,
  Ocr,
  OcrTest,
  ProcessDefinition,
  ProcessDefinitionCreateForm,
  ProcessDefinitionDetails,
  ProcessDefinitionEmailTemplatesDetails,
  ProcessDefinitionFormDetails,
  ProcessDefinitionPrintRender,
  ProcessDefinitionScanning,
  ProcessDefinitionScanningDetails,
  ProcessEditor,
} from 'app/routes/lazyPages';
import ROUTES from 'app/routes/paths';
import onlySignedInPage from 'hocs/onlySignedInPage';

import { ACCESSIBLE_ROLES } from 'constants/roles';

import { accessOrDefaultRedirect } from 'utils/accessOrDefaultRedirect';

const definitionRoutes: RoutesConfig = [
  {
    path: ROUTES.process.definition._(),
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.MANAGERS)(ProcessDefinition)
    ),
    exact: true,
  },
  {
    path: [
      ROUTES.process.editProcess.preview._(),
      ROUTES.process.editProcess._(),
      ROUTES.process.editProcess.forms._(),
      ROUTES.process.editProcess.forms.create(),
      ROUTES.process.editProcess.forms.edit(),
      ROUTES.process.editProcess.forms.details._(),
      ROUTES.process.editProcess.emailTemplates._(),
      ROUTES.process.editProcess.emailTemplates.details._(),
    ],
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.MANAGERS)(ProcessEditor)
    ),
    exact: true,
  },
  {
    path: [
      ROUTES.process.definition.details.version._(),
      ROUTES.process.definition.details._(),
    ],
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.MANAGERS)(
        ProcessDefinitionDetails
      )
    ),
    exact: false,
  },
  {
    path: [
      ROUTES.process.createProcessForm(),
      ROUTES.process.editProcessForm(),
    ],
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.MANAGERS)(
        ProcessDefinitionCreateForm
      )
    ),
    exact: false,
  },
  {
    path: [
      ROUTES.process.formDetails.preview(),
      ROUTES.process.formDetails._(),
    ],
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.MANAGERS)(
        ProcessDefinitionFormDetails
      )
    ),
    exact: true,
  },
  {
    path: [
      ROUTES.process.scanningDetails.preview(),
      ROUTES.process.scanningDetails._(),
    ],
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.MANAGERS)(
        ProcessDefinitionScanningDetails
      )
    ),
    exact: true,
  },
  {
    path: [
      ROUTES.process.emailTemplateDetails.preview(),
      ROUTES.process.emailTemplateDetails._(),
    ],
    exact: true,
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.MANAGERS)(
        ProcessDefinitionEmailTemplatesDetails
      )
    ),
  },
  {
    path: [
      ROUTES.process.definition.services.printRender.preview._(),
      ROUTES.process.definition.services.printRender._(),
    ],
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.MANAGERS)(
        ProcessDefinitionPrintRender
      )
    ),
    exact: false,
  },
  {
    path: [
      ROUTES.process.definition.services.scanning.preview._(),
      ROUTES.process.definition.services.scanning._(),
    ],
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.MANAGERS)(
        ProcessDefinitionScanning
      )
    ),
    exact: false,
  },
  {
    path: [
      ROUTES.process.definition.services.ftpPolling.details._(),
      ROUTES.process.definition.services.ftpPolling.details.preview(),
    ],
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.MANAGERS)(FtpPollingDetails)
    ),
    exact: true,
  },
  {
    path: [
      ROUTES.process.definition.services.ftpPolling.preview._(),
      ROUTES.process.definition.services.ftpPolling._(),
    ],
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.MANAGERS)(FtpPolling)
    ),
    exact: true,
  },
  {
    path: [
      ROUTES.process.definition.services.emailPolling.details._(),
      ROUTES.process.definition.services.emailPolling.details.preview(),
    ],
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.MANAGERS)(EmailPollingDetails)
    ),
    exact: true,
  },
  {
    path: [
      ROUTES.process.definition.services.emailPolling.preview._(),
      ROUTES.process.definition.services.emailPolling._(),
    ],
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.MANAGERS)(EmailPolling)
    ),
    exact: true,
  },
  {
    path: [
      ROUTES.process.definition.services.ocr.preview._(),
      ROUTES.process.definition.services.ocr._(),
    ],
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.MANAGERS)(Ocr)
    ),
    exact: true,
  },
  {
    path: [
      ROUTES.process.definition.services.ocr.test.preview(),
      ROUTES.process.definition.services.ocr.test._(),
    ],
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.MANAGERS)(OcrTest)
    ),
    exact: true,
  },
];

export { definitionRoutes };
