import {
  FieldType,
  ProcessAlias,
  FieldSubType,
} from 'api/endpoints/processFields';

import { DataModelFieldSubType, DataModelFieldType } from 'constants/global';

import { RegExpPattern } from '../../components/molecules/reactHookForm/typing';

const TEXTS = {
  TITLE: 'There are no Data Model fields yet',
  DESCRIPTION:
    'You don’t have any fields in Data Model, but you can add them right now',
  CREATE_LABEL: 'Add Field',
  UPDATE_LABEL: 'Update Field',
  Q_SEARCH: {
    LABEL: 'Are you sure you want to change your quick search field?',
    DESCRIPTION: 'Only one data model field can be used for a quick search.',
  },
  DELETE_FIELD: {
    LABEL: 'Are you sure you want to delete the data model field?',
  },
  DATA_MODEL_FIELD_DELETE_ERROR_MODAL: {
    LABEL: 'The data model field is currently used and cannot be deleted',
    DESCRIPTION:
      'Please check Forms, Process Design, Scanning and Print render.',
    SUBMIT_BTN: 'OK',
  },
  DATA_MODEL_FIELD_EDIT_CONSTANT_ERROR_MODAL: {
    LABEL: 'The constant is currently used and cannot be deleted or renamed.',
    DESCRIPTION: 'Please check Process Design.',
    SUBMIT_BTN: 'OK',
  },
  REJECT: 'Cancel',
  CONFIRM: 'Change',
  REMOVE: 'Remove',
  DELETE: 'Delete',
  ALT: {
    EMPTY: 'Data model empty',
  },
  COPY_ID: 'Data model field ID has been copied',
  EDIT_DATA_MODEL_DIALOG_TITLE: 'Edit Field',
  SHOW_IN_GRID_SUCCESS_TOAST: 'The field is set to be shown in the grid.',
  REMOVE_FROM_GRID_SUCCESS_TOAST: 'The field is hidden in the grid.',
  FIELDS: {
    NAME: 'Name',
    SHORT_NAME: 'Short Name',
    ALIAS: 'Alias',
    DESCRIPTION: 'Description',
    FIELD_TYPE: 'Field Type',
    OPTIONALLY_ADD_TO_COLLECTION: 'Optionally select collection',
    OPTIONS: 'Allowable values',
  },
  BTN_LABEL: 'Add to list',
  REMOVE_FIELD: 'The field has been successfully removed.',
  RESTORE_FIELD: 'The field has been successfully restored',
  REMOVE_ALIAS_MODAL: {
    LABEL: 'Are you sure you want to remove the current field?',
    DESCRIPTION:
      'The field will no longer appear in any configuration and its index (if present) will be deleted.',
    DESCRIPTION2:
      'Make sure the field does not exist within: Process Editor, Print Render, Scanning, My Tasks, Forms.',
    DESCRIPTION3:
      'Failure to remove all references to the field will result in unexpected errors!',
    CANCEL_BTN: 'Cancel',
    SUBMIT_BTN: 'Remove',
  },
};

const TABLE_MENU_ACCESSOR = {
  Q_SEARCH: 'q_search',
  SET_A_SEARCH: 'set_a_search',
  UNSET_A_SEARCH: 'unset_a_search',
  GET_ID: 'get_id',
  SHOW_FIELD_IN_GRID: 'display_field_in_grid',
  HIDE_FIELD_FROM_GRID: 'remove_field_from_grid',
  SET_INDEXED: 'set_indexed',
  REMOVE_INDEXED: 'remove_indexed',
  EDIT: 'edit',
  DELETE: 'delete',
  REMOVE_FIELD: 'remove_field',
  RESTORE_FIELD: 'restore_field',
};

/**
 * Returns if the field should have an index set on it when setting the field as advanced or quick search.
 *
 * Note, while subType has a predefined set of strings, in the current model, subtype can be undefined.
 * Undefined !== rich_text returns true.
 * @param field the field
 */
const SHOULD_ADD_INDEX_WHEN_SETTING_A_OR_Q_SEARCH = (
  field: ProcessAlias
): boolean =>
  !field.isForIndex &&
  (field.type === 'select' ||
    field.type === 'date' ||
    (field.type === 'text' && field.subType !== 'rich_text'));

export interface ISelectOptionsInformation {
  label: string;
  parentLabel?: string;
  type: FieldType;
  subtype?: FieldSubType;
  metaData: {
    maskType: DataModelFieldType | DataModelFieldSubType;
  };
}

enum PARENT_FIELDS {
  'Text Field' = 'Text Field',
  'Number' = 'Number',
  'Date' = 'Date',
}

const SELECT_OPTIONS_INFORMATION: ISelectOptionsInformation[] = [
  {
    label: 'Text Field: Default',
    parentLabel: PARENT_FIELDS['Text Field'],
    type: 'text',
    metaData: {
      maskType: DataModelFieldType.Text,
    },
  },
  {
    label: 'Text Field: Email',
    parentLabel: PARENT_FIELDS['Text Field'],
    type: 'text',
    metaData: {
      maskType: DataModelFieldSubType.TextEmail,
    },
    subtype: 'email',
  },
  {
    label: 'Text Field: URL',
    parentLabel: PARENT_FIELDS['Text Field'],
    type: 'text',
    metaData: {
      maskType: DataModelFieldSubType.TextUrl,
    },
    subtype: 'url',
  },
  {
    label: 'Text Field: Phone',
    parentLabel: PARENT_FIELDS['Text Field'],
    type: 'text',
    metaData: {
      maskType: DataModelFieldSubType.TextPhone,
    },
    subtype: 'phone',
  },
  {
    label: 'Text Field: Rich Text',
    parentLabel: PARENT_FIELDS['Text Field'],
    type: 'text',
    metaData: {
      maskType: DataModelFieldSubType.TextRichText,
    },
    subtype: 'rich_text',
  },
  {
    label: 'Number Field: Default',
    parentLabel: PARENT_FIELDS['Number'],
    type: 'number',
    metaData: {
      maskType: DataModelFieldType.Number,
    },
  },
  {
    label: 'Number: Phone',
    parentLabel: PARENT_FIELDS['Number'],
    type: 'number',
    metaData: {
      maskType: DataModelFieldSubType.NumberPhone,
    },
    subtype: 'phone',
  },
  {
    label: 'Date Field: MM/DD/YYYY',
    parentLabel: PARENT_FIELDS['Date'],
    type: 'date',
    metaData: {
      maskType: DataModelFieldType.Date,
    },
  },
  {
    label: 'Date DD / MM / YYYY',
    parentLabel: PARENT_FIELDS['Date'],
    type: 'date',
    metaData: {
      maskType: DataModelFieldSubType.DateReverse,
    },
  },
  {
    label: 'Date MM / DD / YYYY',
    parentLabel: PARENT_FIELDS['Date'],
    type: 'date',
    metaData: {
      maskType: DataModelFieldSubType.DateDefault,
    },
  },
  {
    label: 'Date MM . DD . YYYY',
    parentLabel: PARENT_FIELDS['Date'],
    type: 'date',
    metaData: {
      maskType: DataModelFieldSubType.DateDots,
    },
  },
  {
    label: 'File',
    type: 'file',
    metaData: {
      maskType: DataModelFieldType.File,
    },
  },
  {
    label: 'Select One',
    type: 'select',
    metaData: {
      maskType: DataModelFieldType.Select,
    },
  },
];

// The names of these must match up with DataModelFieldModal!
const FIELDS = {
  NAME: {
    name: 'name',
    label: TEXTS.FIELDS.NAME,
    placeholder: TEXTS.FIELDS.NAME,
    pattern: {
      regExp: /^.{1,50}$/,
      errorMessage: 'The name must be at most 50 characters long',
    } as RegExpPattern,
  },
  /**
   * Max 10 characters long, alphanumeric with underscores and hyphens
   */
  SHORT_NAME: {
    name: 'shortName',
    label: TEXTS.FIELDS.SHORT_NAME,
    placeholder: TEXTS.FIELDS.SHORT_NAME,
    pattern: {
      regExp: /^[a-zA-Z][a-zA-Z0-9_]{0,49}$/,
      errorMessage:
        'The short name must be up to 50 characters long with alphanumeric characters and non-leading underscores.',
    } as RegExpPattern,
  },
  DESCRIPTION: {
    name: 'description',
    label: TEXTS.FIELDS.DESCRIPTION,
    placeholder: TEXTS.FIELDS.DESCRIPTION,
    pattern: {
      regExp: /^.{1,200}$/,
      errorMessage: 'The description must be at most 200 characters long.',
    } as RegExpPattern,
  },
  FIELD_TYPE: {
    name: 'selectOption',
    label: TEXTS.FIELDS.FIELD_TYPE,
    placeholder: TEXTS.FIELDS.FIELD_TYPE,
  },
  OPTIONS: {
    name: 'select',
    label: TEXTS.FIELDS.OPTIONS,
  },
};

export {
  TEXTS,
  TABLE_MENU_ACCESSOR,
  SELECT_OPTIONS_INFORMATION,
  PARENT_FIELDS,
  FIELDS,
  SHOULD_ADD_INDEX_WHEN_SETTING_A_OR_Q_SEARCH,
};
