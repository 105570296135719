import { JsonPatch } from '../api/types/jsonPatch';

export type Patch = Record<string, any>;
export type PatchTyped<T> = Partial<Record<keyof T, any>>;

export const patchToJSONPatch = (patch: Patch) => {
  const result: JsonPatch[] = [];
  Object.entries(patch).forEach(([fieldName, value]) => {
    result.push(new JsonPatch(fieldName, patch[fieldName]));
  });

  return result;
};
