import {
  ApiResponseAsync,
  axiosResponseToApiResponse,
  translateAxiosErrorToApiError,
} from 'container/models';

import { authHeaderName } from 'api/apiReqSagaCreator/config';
import { handleExpiredTokens } from 'api/apiReqSagaCreator/httpRequest';
import { ApiPaths } from 'api/constants';
import { ProcessInstanceDetails } from 'api/endpoints/processInstance';

import ApiUrlParams from 'constants/apiUrlParams';

import { valueIsString } from '../../../utils/typeChecking';
import BaseRepo from '../../BaseRepo';
import { LockDocumentResponse } from '../indexingDocuments/IIndexingDocumentsRepo';
import { IProcessInstanceRepo } from './IProcessInstanceRepo';

export class ProcessInstanceRepo
  extends BaseRepo
  implements IProcessInstanceRepo
{
  getProcessInstance = (link: Link): ApiResponseAsync<ProcessInstanceDetails> =>
    this.helpers.handleGet<ProcessInstanceDetails>(link.href, {
      responseStopTransformPaths: ['data_model.variables'],
    });

  getIndexingInputFile = (
    processInstance: ProcessInstanceDetails,
    lockDocumentResponse: LockDocumentResponse
  ): ApiResponseAsync<Blob> => {
    const lockDocFieldDataModel =
      lockDocumentResponse.document.fieldDataModel.find(
        (fieldGeneral) =>
          fieldGeneral.id === lockDocumentResponse.document.fileFieldId
      );
    if (!lockDocFieldDataModel) {
      return Promise.resolve({
        status: 404,
        statusText: 'File field not found on process data model',
        data: [
          {
            code: 404,
            description: 'File field not found on process data model',
            message: 'File field not found on process data model',
          },
        ],
      });
    }
    const url =
      processInstance.dataModel!.variables[
        lockDocFieldDataModel.alias.shortName
      ];

    if (!valueIsString(url))
      return Promise.resolve({
        status: 404,
        statusText: 'File URL not found in process instance',
        data: [
          {
            code: 404,
            description: 'File URL not found in process instance',
            message: 'File URL not found in process instance',
          },
        ],
      });

    return axiosResponseToApiResponse(
      handleExpiredTokens(() =>
        this.client.get<Blob>(url, {
          responseType: 'arraybuffer',
          headers: {
            [authHeaderName]: this.tokenAccessor(),
            'Content-Type': 'application/pdf',
            Accept: 'application/pdf',
          },
        })
      )
    );
  };

  deleteProcessInstance = (
    processInstanceId: string,
    processReleaseId: string
  ) =>
    handleExpiredTokens(() =>
      this.client.delete(
        ApiPaths.processes.byId.instances.byId._({
          [ApiUrlParams.releaseOrDraftProcessId]: processReleaseId,
          [ApiUrlParams.processInstanceId]: processInstanceId,
        }),
        {
          headers: {
            [authHeaderName]: this.tokenAccessor(),
          },
        }
      )
    )
      .then((response) => response.data)
      .catch(translateAxiosErrorToApiError);
}
