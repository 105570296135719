export class JsonPatch {
  readonly op: string;
  readonly path: string;
  readonly value: string;

  public constructor(path: string, value: string) {
    this.op = 'replace';
    this.path = '/' + path;
    this.value = value;
  }
}
