import { ExternalFormGeneral } from '../../../pages/externalForm/typing';
import BaseRepo from '../../BaseRepo';
import { ApiResponseAsync } from '../../models';
import { IExternalFormsRepo } from './IExternalForms';

export class ExternalFormsRepo extends BaseRepo implements IExternalFormsRepo {
  getExternalForm = (ott: string): ApiResponseAsync<ExternalFormGeneral> => {
    const link = `/external-form/validate?ott=${encodeURIComponent(ott)}`;

    return this.helpers.handleGet<ExternalFormGeneral>(link, {
      responseStopTransformPaths: ['params'],
    });
  };
}
