import {
  ApiResponse,
  ApiResponseAsync,
  isApiError,
  translateAxiosErrorToApiError,
} from 'container/models';
import { SearchRequest } from 'container/search';

import { authHeaderName } from 'api/apiReqSagaCreator/config';
import { handleExpiredTokens } from 'api/apiReqSagaCreator/httpRequest';
import { ApiPaths } from 'api/constants';
import { ProcessGeneral } from 'api/endpoints/processes';
import { ProcessInstanceGeneral } from 'api/endpoints/processInstance';

import ApiUrlParams from 'constants/apiUrlParams';

import {
  FieldBase,
  FieldGeneral,
  ProcessAlias,
} from '../../../api/endpoints/processFields';
import { UploadFileWhileExecuteResult } from '../../../api/endpoints/processScan';
import { UPLOAD_FILE_FIELD_NAME } from '../../../constants/global';
import BaseRepo from '../../BaseRepo';
import { buildBadRequestApiError } from '../../helpers/errors';
import { ActiveReleaseFields, IProcessRepo } from './IProcessRepo';

const { REACT_APP_API_DOCUMENT_SERVICE: documentServiceUrl } = process.env;

export class ProcessRepo extends BaseRepo implements IProcessRepo {
  getProcessDetails = (
    draftProcessId: string
  ): ApiResponseAsync<ProcessGeneral> =>
    handleExpiredTokens(() =>
      this.client.get<ProcessGeneral>(
        ApiPaths.processes.byId._({
          [ApiUrlParams.releaseOrDraftProcessId]: draftProcessId,
        }),
        {
          headers: { [authHeaderName]: this.tokenAccessor() },
        }
      )
    ).then(({ data }) => data, translateAxiosErrorToApiError);

  getProcessDraft = async (
    draftProcessId: string
  ): Promise<ApiResponse<ProcessGeneral>> => {
    const url = ApiPaths.processes.byId._({
      [ApiUrlParams.releaseOrDraftProcessId]: draftProcessId,
    });
    const response = await this.helpers.handleGet<ProcessGeneral>(url);
    if (isApiError(response)) {
      return response;
    }

    if (response.state !== 'draft') {
      return buildBadRequestApiError(new Error('process is not draft'));
    }

    return response;
  };

  getActiveReleaseFields = async (
    draft: ProcessGeneral
  ): ApiResponseAsync<ActiveReleaseFields> => {
    const link = draft.links?.['getActiveReleaseFields'];
    if (!link) {
      return buildBadRequestApiError(
        new Error('link "get active release fields" is not available')
      );
    }

    return this.helpers.handleGet<ActiveReleaseFields>(link.href);
  };

  getReleaseFields = async (
    processInstance: ProcessInstanceGeneral,
    query: SearchRequest
  ): ApiResponseAsync<DataWithTotal<FieldGeneral>> => {
    const link = processInstance.links?.['getDataModel'];
    if (!link) {
      return buildBadRequestApiError(
        new Error('link "get data model" is not available')
      );
    }

    return this.helpers.executeSearch<DataWithTotal<FieldGeneral>>(link, query);
  };

  uploadFile = async (
    file: File,
    release: ProcessGeneral,
    field: FieldBase
  ): ApiResponseAsync<UploadFileWhileExecuteResult> => {
    const fileFormData = new FormData();
    fileFormData.append(UPLOAD_FILE_FIELD_NAME, file);

    const relativeUrl = ApiPaths.field.byScanInstanceId.byFieldId._({
      [ApiUrlParams.fieldId]: field.id,
      [ApiUrlParams.processInstanceId]: release.id,
    });
    return this.helpers.handlePost(relativeUrl, fileFormData, {
      baseUrl: documentServiceUrl,
    });
  };

  deleteFile = async (
    payloadFromUploadFile: UploadFileWhileExecuteResult
  ): ApiResponseAsync<void> => {
    const link = payloadFromUploadFile.links?.['delete'];
    if (!link) {
      return buildBadRequestApiError(
        new Error('link "delete file" is not available')
      );
    }

    return this.helpers.handleDelete('', {
      baseUrl: link.href,
    });
  };

  uploadFileWithAlias = async (
    file: File,
    alias: ProcessAlias
  ): ApiResponseAsync<UploadFileWhileExecuteResult> => {
    const link = alias.links?.['uploadFile'];
    if (!link) {
      return buildBadRequestApiError(
        new Error('link "upload file with alias" is not available')
      );
    }

    const fileFormData = new FormData();
    fileFormData.append(UPLOAD_FILE_FIELD_NAME, file);

    return this.helpers.handlePost(link.href, fileFormData);
  };

  getFieldGeneralFields = async (
    draftProcessId: string
  ): ApiResponseAsync<DataWithTotal<FieldGeneral>> => {
    const url = ApiPaths.processes.byId.fields._({
      [ApiUrlParams.releaseOrDraftProcessId]: draftProcessId,
    });
    return this.helpers.handleGet<DataWithTotal<FieldGeneral>>(url);
  };
}
