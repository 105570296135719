import {
  ApiResponseAsync,
  translateAxiosErrorToApiError,
} from 'container/models';

import { authHeaderName } from 'api/apiReqSagaCreator/config';
import { handleExpiredTokens } from 'api/apiReqSagaCreator/httpRequest';

import BaseRepo from '../../BaseRepo';
import {
  FreshdeskTokenResponse,
  IFreshdeskTokenRepo,
} from './IFreshdeskTokenRepo';

export class FreshdeskTokenRepo
  extends BaseRepo
  implements IFreshdeskTokenRepo
{
  getFreshdeskToken = (): ApiResponseAsync<FreshdeskTokenResponse> =>
    handleExpiredTokens(() =>
      this.client.post<FreshdeskTokenResponse>(
        '/generate-freshdesk-token',
        {},
        {
          headers: {
            [authHeaderName]: this.tokenAccessor(),
          },
        }
      )
    )
      .then((response) => response.data)
      .catch(translateAxiosErrorToApiError);
}
