import { combineReducers } from 'redux';
import { ObligationTypeField } from 'types/field';

import { API_REQ_ACTION_TYPES } from 'api/actions';
import { FormGeneral } from 'api/endpoints/processForm';

import { ApiObligationType, ObligationType } from 'constants/form';

import { FORMS_DETAILS_ACTION_TYPES } from './actions';

export interface FormsState {
  list: Nullable<FormGeneral[]>;
  total: Nullable<number>;
  details: Nullable<FormGeneral>;
  initialForm: Nullable<FormGeneral>;
  obligationFields: Nullable<ObligationTypeField[]>;
}

interface ProcessFormsListParams {
  type: string;
  payload: DataWithTotal<FormGeneral>;
  meta: {
    input: {
      isInitial?: boolean;
    };
  };
}

const processForms = combineReducers<FormsState>({
  list(state = null, { type, payload, meta }: ProcessFormsListParams) {
    switch (type) {
      case API_REQ_ACTION_TYPES.PROCESSES.GET_ALL_FORMS.SUCCESS:
        return !meta.input?.isInitial ? (payload.data ?? null) : state;
      default:
        return state;
    }
  },
  total(state = null, { type, payload, meta }: ProcessFormsListParams) {
    switch (type) {
      case API_REQ_ACTION_TYPES.PROCESSES.GET_ALL_FORMS.SUCCESS:
        return !meta.input?.isInitial ? (payload.total ?? null) : state;
      default:
        return state;
    }
  },
  details(
    state = null,
    { type, payload }: { type: string; payload: FormGeneral }
  ) {
    switch (type) {
      case FORMS_DETAILS_ACTION_TYPES.SET_PROCESS_FORMS_DETAILS:
        return payload || null;
      default:
        return state;
    }
  },
  initialForm(state = null, { type, payload }) {
    switch (type) {
      case API_REQ_ACTION_TYPES.PROCESSES.VERSIONS.CREATE.SUCCESS:
        return null;
      case FORMS_DETAILS_ACTION_TYPES.SET_INITIAL_FORM:
        return payload ?? null;
      default:
        return state;
    }
  },
  obligationFields(
    state = null,
    {
      type,
      payload,
    }: { type: string; payload: ObligationTypeField | FormGeneral }
  ) {
    switch (type) {
      case FORMS_DETAILS_ACTION_TYPES.CLEAR_OBLIGATION_FIELDS:
        return null;
      case FORMS_DETAILS_ACTION_TYPES.SET_PROCESS_FORMS_DETAILS:
        return [
          ...(state ?? []),
          ...((payload as FormGeneral)?.fields ?? []).map(
            ({ obligationType, ...rest }) => {
              let typeLocal;
              switch (obligationType) {
                case ApiObligationType.fillRequired:
                  typeLocal = ObligationType.required;
                  break;
                case ApiObligationType.fillOptional:
                  typeLocal = ObligationType.optional;
                  break;
                default:
                  typeLocal = ObligationType.readOnly;
              }
              return { ...rest, obligationType: typeLocal };
            }
          ),
        ];
      case FORMS_DETAILS_ACTION_TYPES.EDIT_OBLIGATION_FIELDS:
        return [
          ...(state ?? []).filter((e) => e.id !== payload?.id),
          payload as ObligationTypeField,
        ];
      default:
        return state;
    }
  },
});

export default processForms;
