import { SearchTypes } from 'constants/global';

import subSelectorCreator from 'utils/subSelectorCreator';

import { FieldState } from './reducer';

const createFieldSelector = subSelectorCreator<FieldState>(
  (state) => state.field
);

const FieldSelectors = {
  getUniqueField: (type: SearchTypes) =>
    createFieldSelector(({ uniqueField }) => uniqueField[type]),
  getUniqueFields: createFieldSelector(({ uniqueField }) => uniqueField),
  getQSField: createFieldSelector(({ qSField }) => qSField),
  getReFetchFieldsTrigger: createFieldSelector(
    ({ reFetchFieldsTrigger }) => reFetchFieldsTrigger
  ),
  getListByType: createFieldSelector(
    ({ listByType }) =>
      (type: string) =>
        listByType[type]
  ),
};

export default FieldSelectors;
