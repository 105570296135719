import {
  valueIsArrayOfStrings,
  valueIsDate,
  valueIsNumber,
  valueIsString,
} from '../utils/typeChecking';
import { Filter, FilterItem, OrderBy, SearchRequest } from './search';

export interface OData {
  filter?: string;
  orderBy?: string;
  top?: number;
  skip?: number;
  count?: boolean;
}

export const convertSearchToOData = (request: SearchRequest) => {
  const result: OData = {
    filter: convertFilterToODataExpression(request.filter),
    orderBy: convertOrderByToOData(request.orderBy),
    top: request.top,
    skip: request.skip,
    count: request.count,
  };

  return result;
};

function convertFilterToODataExpression(filter?: Filter): string | undefined {
  if (!filter) return undefined;
  const filterItems = filter.items.map((item: FilterItem) => {
    switch (item.operation) {
      case 'equal':
        return `${item.field} ${item.not ? 'ne' : 'eq'} ${buildSQLValue(item.value)}`;
      case 'greater':
        return `${item.field} ${item.not ? 'lte' : 'gt'} ${buildSQLValue(item.value)}`;
      case 'greater_or_equal':
        return `${item.field} ${item.not ? 'lt' : 'ge'} ${buildSQLValue(item.value)}`;
      case 'less':
        return `${item.field} ${item.not ? 'gte' : 'lt'} ${buildSQLValue(item.value)}`;
      case 'less_or_equal':
        return `${item.field} ${item.not ? 'gt' : 'le'} ${buildSQLValue(item.value)}`;
      case 'starts_with':
        if (valueIsString(item.value)) {
          const expr = `startswith(${item.field},${buildSQLValue(item.value)})`;
          return item.not ? `not (${expr})` : expr;
        }

        throw new Error(
          `operation 'starts_with' requires string value parameter`
        );
      case 'end_with':
        if (valueIsString(item.value)) {
          const expr = `endswith(${item.field},${buildSQLValue(item.value)})`;
          return item.not ? `not (${expr})` : expr;
        }

        throw new Error(`operation 'end_with' requires string value parameter`);
      case 'contains':
        if (valueIsString(item.value)) {
          const expr = `contains(${item.field},${buildSQLValue(item.value)})`;
          return item.not ? `not (${expr})` : expr;
        }

        throw new Error(`operation 'contains' requires string value parameter`);
      case 'is_null':
        return `${item.field} ${item.not ? 'ne' : 'eq'} null`;
      case 'in':
        if (valueIsArrayOfStrings(item.value)) {
          const sqlIn =
            `${item.field} in ` +
            '(' +
            item.value.map((item) => buildSQLValue(item)).join(',') +
            ')';
          return item.not ? `not (${sqlIn})` : sqlIn;
        }

        throw new Error(
          `search operation 'in' requires array of strings (field name: ${item.field})`
        );
      default:
        throw new Error(`unsupported operation ${item.operation}`);
    }
  });

  return filterItems.join(` ${filter.operator} `);
}

function convertOrderByToOData(orderBy?: OrderBy[]): string | undefined {
  if (!orderBy) return undefined;
  return orderBy
    .map((item: OrderBy) => {
      let expr = item.field;
      if (item.order) {
        expr = `${expr} ${item.order.toLowerCase()}`;
      }

      return expr;
    })
    .join(',');
}

function buildSQLValue(value: any): string {
  if (valueIsString(value)) {
    // eslint-disable-next-line quotes
    return `'${value.replace("'", "''")}'`;
  }

  if (valueIsNumber(value)) {
    return value.toString();
  }

  if (valueIsDate(value)) {
    return new Intl.DateTimeFormat('fr-CA', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(value);
  }

  return JSON.stringify(value);
}
