import { call, put } from 'redux-saga/effects';

import { ApiSagaResult } from 'api/apiReqSagaCreator';
import ProcessForm, {
  FieldWithObligationType,
  GetAllFormsResult,
  GetFormByIdResult,
} from 'api/endpoints/processForm';

import { ProcessForm as ProcessFormActions } from 'store/forms/actions';

import ApiUrlParams from 'constants/apiUrlParams';

function* getInitialFormData(releaseOrDraftProcessId: string) {
  const { result }: ApiSagaResult<GetAllFormsResult> = yield call(
    ProcessForm.getAllForms,
    {
      [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
      isInitial: true,
    },
    {
      withPrevPageOnLessTotal: false,
    }
  );

  if ((result as GetAllFormsResult)?.data && releaseOrDraftProcessId) {
    const { result: formDetailsResult }: ApiSagaResult<GetFormByIdResult> =
      yield call(ProcessForm.getFormById, {
        [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
        [ApiUrlParams.formId]: ((result as GetAllFormsResult)?.data || [])[0]
          ?.id,
      });

    const updatedFields: FieldWithObligationType[] =
      (formDetailsResult as GetFormByIdResult)?.fields || [];

    yield put(
      ProcessFormActions.setInitialForm({
        ...(formDetailsResult as GetFormByIdResult),
        fields: updatedFields,
      })
    );

    yield ((result as GetAllFormsResult)?.data || [])[0];
  }

  yield null;
}

export default getInitialFormData;
