import { takeLatest } from 'redux-saga/effects';

import { API_REQ_ACTION_TYPES } from 'api/actions';

import fileUploadSuccess from 'store/printRender/sagas/fileUploadSuccess';

import { PROCESS_ACTION_TYPES } from './actions';
import {
  addFieldFailure,
  createVersionFailure,
  exportProcess,
  getInstancesListError,
  handleErrorGetInstancesById,
  handleErrorSearch,
  handleStartSubmittingDynamicForm,
  importProcessFailure,
  importProcessSuccess,
  redirectToProcess,
  redirectToProcessDefinition,
  redirectToProcessDetails,
  reFetchList,
  reFetchModelForms,
  reFetchProcessDetails,
  reFetchProcessesForms,
  sendInstancesFilesSuccess,
} from './sagas';

// TODO: MOVE SAGA TO ITS OWN ENTITY
function* watchersSaga() {
  yield takeLatest(
    [API_REQ_ACTION_TYPES.PROCESSES.CREATE.SUCCESS],
    reFetchList
  );

  yield takeLatest(
    [API_REQ_ACTION_TYPES.INSTANCE.SEND_FILES_VIA_EMAIL.SUCCESS],
    sendInstancesFilesSuccess
  );

  yield takeLatest(
    [API_REQ_ACTION_TYPES.PROCESSES.CREATE.SUCCESS],
    redirectToProcess
  );

  yield takeLatest(
    [API_REQ_ACTION_TYPES.PROCESSES.CREATE_MODEL.SUCCESS],
    reFetchModelForms
  );

  yield takeLatest(
    [API_REQ_ACTION_TYPES.PROCESSES.CREATE_MODEL.ERROR],
    addFieldFailure
  );

  yield takeLatest(
    [API_REQ_ACTION_TYPES.PROCESSES.CREATE_FORM.SUCCESS],
    reFetchProcessesForms
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.INSTANCE.FINISH_INSTANCE.SUCCESS,
    redirectToProcessDetails
  );

  yield takeLatest(
    [PROCESS_ACTION_TYPES.START_SUBMIT_INSTANCE_TASK],
    handleStartSubmittingDynamicForm
  );

  yield takeLatest(
    [API_REQ_ACTION_TYPES.PROCESSES.SEARCH_BY_ID.ERROR],
    handleErrorSearch
  );

  yield takeLatest(
    [API_REQ_ACTION_TYPES.PROCESSES.GET_BY_ID.ERROR],
    handleErrorGetInstancesById
  );

  yield takeLatest(
    [
      API_REQ_ACTION_TYPES.PROCESSES.VERSIONS.ACTIVATE.SUCCESS,
      API_REQ_ACTION_TYPES.PROCESSES.VERSIONS.CREATE.SUCCESS,
      API_REQ_ACTION_TYPES.PROCESSES.UPDATE_BY_ID.SUCCESS,
      API_REQ_ACTION_TYPES.PROCESSES.DEACTIVATE.SUCCESS,
      API_REQ_ACTION_TYPES.PROCESSES.IMPORT_IN.SUCCESS,
    ],
    reFetchProcessDetails // get latest release
  );

  yield takeLatest(
    [API_REQ_ACTION_TYPES.PROCESSES.UPDATE_BY_ID.SUCCESS],
    redirectToProcess
  );

  yield takeLatest(
    [API_REQ_ACTION_TYPES.PROCESSES.DELETE_BY_ID.SUCCESS],
    redirectToProcessDefinition
  );

  yield takeLatest(
    [API_REQ_ACTION_TYPES.INSTANCE.GET_INSTANCE_BY_ID.ERROR],
    getInstancesListError
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.PROCESSES.EXPORT.SUCCESS,
    exportProcess
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.PROCESSES.IMPORT.SUCCESS,
    importProcessSuccess
  );

  yield takeLatest(
    [
      API_REQ_ACTION_TYPES.PROCESSES.IMPORT.ERROR,
      API_REQ_ACTION_TYPES.PROCESSES.IMPORT_IN.ERROR,
    ],
    importProcessFailure
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.INSTANCE.UPDATE_FILE_FIELD_VALUE.SUCCESS,
    fileUploadSuccess
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.PROCESSES.VERSIONS.CREATE.ERROR,
    createVersionFailure
  );
}

export default watchersSaga;
