import ROUTES from 'app/routes/paths';
import { UiWithReleaseOrDraftProcessId } from 'app/routes/uiPathParams';
import { getLocation } from 'connected-react-router';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { fork, select } from 'redux-saga/effects';

import Processes from 'api/endpoints/processes';

import ApiUrlParams from 'constants/apiUrlParams';

function* reFetchProcessDetails() {
  const { pathname } = yield select(getLocation);

  const {
    params: { releaseOrDraftProcessId },
  }: match<UiWithReleaseOrDraftProcessId> = yield matchPath(pathname, {
    path: [
      ROUTES.process.definition.details._(),
      ROUTES.process.definition.details.version._(),
      ROUTES.process.execution.details._(),
      ROUTES.process.definition.services.printRender._(),
      ROUTES.process.definition.services.printRender.preview._(),
      ROUTES.process.definition.services.scanning._(),
      ROUTES.process.definition.services.scanning.preview._(),
      ROUTES.process.editProcess._(),
      ROUTES.process.editProcess.preview._(),
    ],
  });

  yield fork(Processes.getById, {
    [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
  });
}

export default reFetchProcessDetails;
