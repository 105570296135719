import { WithReleaseOrDraftProcessId, WithFieldId } from 'api/types/pathParams';

import ApiUrlParams from 'constants/apiUrlParams';
import {
  DataModelFieldSubType,
  DataModelFieldType,
  SearchTypes,
} from 'constants/global';

import { excludeObjectProperty } from 'utils/normalizers/object';

import { API_REQ_ACTION_TYPES } from '../actions';
import apiReqSagaCreator from '../apiReqSagaCreator';
import { ApiPaths, HTTP_METHODS } from '../constants';

export interface CreateData {
  data: {
    name: string;
    description?: string;
    alias: string;
    metadata: {
      maskType: string;
    };
    type: FieldType;
    options?: string[];
  };
}

/** @deprecated - use {@link DataModelFieldType} */
export type FieldType =
  | 'text'
  | 'date'
  | 'number'
  | 'file'
  | 'collection'
  | 'select'
  | 'guid';

export type FieldSubType = 'rich_text' | 'email' | 'url' | 'phone' | '';

export interface AliasGeneral extends ById {
  name: string;
  type: DataModelFieldType;
  fields: FieldGeneral[];
  shortName: string;
}

export interface MetaData {
  maskType: DataModelFieldType | DataModelFieldSubType;
}

export interface ProcessAlias extends FieldBase, WithHypermedia {
  shortName: string;
  subType: FieldSubType;
  metadata: MetaData;
  isForIndex: boolean;
  isRemoved: boolean;
}

export interface FieldBase extends BaseEntity {
  type: DataModelFieldType;
  description?: string;
  isForQuickSearch: boolean;
  isForAdvancedSearch: boolean;
  isForView: boolean;
  options?: string[];
}

export interface BaseField extends FieldBase {
  alias: ProcessAlias;
  aliasId: string;
  metadata: MetaData;
}

export interface FieldGeneral extends BaseField {
  collection?: BaseField[];
}

export interface GetByType extends Pagination {
  type?: DataModelFieldType;
}

export interface GetDynamic extends Pagination {
  for?: SearchTypes;
}

export type GetDFMsByTypeParams = GetByType & WithReleaseOrDraftProcessId;
export type GetDMFsByTypeResult = DataWithTotal<FieldGeneral>;
export type DeleteDataModelFieldParams = WithReleaseOrDraftProcessId &
  WithFieldId;
export type DeleteDataModelFieldsResult = unknown;
export type GetAliasesParams = GetDynamic & WithReleaseOrDraftProcessId;
export type GetAliasesResult = DataWithTotal<AliasGeneral>;
export type GetFieldsByIdParams = GetDynamic & WithReleaseOrDraftProcessId;
export type GetFieldsByIdResult = DataWithTotal<FieldGeneral>;
export type GetFieldsByFieldIdParams = WithReleaseOrDraftProcessId &
  WithFieldId;
export type GetFieldsByFieldIdResult = FieldGeneral;

const ProcessFields = {
  getDMFsByType: apiReqSagaCreator<GetDFMsByTypeParams, GetDMFsByTypeResult>(
    API_REQ_ACTION_TYPES.FIELDS.GET_BY_PROCESS_TYPE_ID,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId.fields._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          params[ApiUrlParams.releaseOrDraftProcessId],
      }),
      params: excludeObjectProperty(
        params,
        ApiUrlParams.releaseOrDraftProcessId
      ),
    })
  ),
  getAliases: apiReqSagaCreator<GetAliasesParams, GetAliasesResult>(
    API_REQ_ACTION_TYPES.FIELDS.GET_ALIASES,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId.fields.aliases._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          params[ApiUrlParams.releaseOrDraftProcessId],
      }),
      params: {
        limit: params.limit,
        offset: params.offset,
        for: params.for,
      },
    })
  ),
  getFieldsById: apiReqSagaCreator<GetFieldsByIdParams, GetFieldsByIdResult>(
    API_REQ_ACTION_TYPES.FIELDS.GET_BY_PROCESS_ID,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId.fields._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          params[ApiUrlParams.releaseOrDraftProcessId],
      }),
      params: {
        limit: params.limit,
        offset: params.offset,
        for: params.for,
      },
    }),
    {
      withPrevPageOnLessTotal: true,
    }
  ),
  getFieldsByFieldId: apiReqSagaCreator<
    GetFieldsByFieldIdParams,
    GetFieldsByFieldIdResult
  >(API_REQ_ACTION_TYPES.FIELDS.GET_BY_PROCESS_ID_AND_FIELD_ID, (params) => ({
    method: HTTP_METHODS.GET,
    url: ApiPaths.processes.byId.fields.byId({
      [ApiUrlParams.releaseOrDraftProcessId]:
        params[ApiUrlParams.releaseOrDraftProcessId],
      [ApiUrlParams.fieldId]: params[ApiUrlParams.fieldId],
    }),
  })),
};

export function getFieldProperty<T>(
  obj: FieldBase | undefined,
  key: string
): T | undefined {
  if (!obj) return undefined;
  return key in obj ? (obj[key as keyof FieldBase] as T) : undefined;
}

export default ProcessFields;
