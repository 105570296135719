import { combineReducers } from 'redux';
import { FullScanConfiguration } from 'types/scanning';

import { API_REQ_ACTION_TYPES } from 'api/actions';
import {
  GetAllScanConfigurationsResult,
  ScanConfigurationGeneral,
} from 'api/endpoints/processScan';

import { PROCESS_SCAN_CONFIG } from './actions';

export interface ScanConfigState {
  list: Nullable<ScanConfigurationGeneral[]>;
  filedDetails: Nullable<FullScanConfiguration>;
}

const processScanConfig = combineReducers<ScanConfigState>({
  list(
    state = null,
    { type, payload }: { type: string; payload: GetAllScanConfigurationsResult }
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.SCANNING.GET_LIST.SUCCESS:
        return payload ? payload.data : null;
      case PROCESS_SCAN_CONFIG.CLEAR_LIST:
        return null;
      default:
        return state;
    }
  },
  filedDetails(
    state = null,
    { type, payload }: { type: string; payload: FullScanConfiguration }
  ) {
    switch (type) {
      case PROCESS_SCAN_CONFIG.SET_SCAN_CONFIG:
        return payload || null;

      default:
        return state;
    }
  },
});

export default processScanConfig;
