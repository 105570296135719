import { Fields } from 'components/organisms/form/createTemplate';

import {
  WithReleaseOrDraftProcessId,
  WithEmailTemplateId,
} from 'api/types/pathParams';

import ApiUrlParams from 'constants/apiUrlParams';

import { excludeObjectProperty } from 'utils/normalizers/object';

import { API_REQ_ACTION_TYPES } from '../actions';
import apiReqSagaCreator from '../apiReqSagaCreator';
import { ApiPaths, HTTP_METHODS } from '../constants';

export interface IsUsedInfo {
  isUsed: boolean;
  scenarioId?: string;
  scenarioName?: string;
}

export interface EmailTemplateGeneral extends ById {
  subject?: string;
  body?: string;
  index: string;
  replyTo: string;
  bcc: Nullable<string[]>;
  emailProviderId: string;
}

export type FieldsWithNormalizedBcc = Omit<
  Fields,
  'bcc' | 'emailProviderId'
> & { bcc?: string[]; emailProviderId: string };
export type GetListParams = Pagination & WithReleaseOrDraftProcessId;
export type GetListResult = DataWithTotal<EmailTemplateGeneral>;
export type CreateParams = WithReleaseOrDraftProcessId &
  FieldsWithNormalizedBcc;
export type CreateResult = EmailTemplateGeneral;
export type UpdateParams = WithReleaseOrDraftProcessId &
  WithEmailTemplateId &
  FieldsWithNormalizedBcc;
export type UpdateResult = EmailTemplateGeneral;
export type DeleteParams = WithReleaseOrDraftProcessId & WithEmailTemplateId;
export type DeleteResult = unknown;
export type GetDetailsParams = WithReleaseOrDraftProcessId &
  WithEmailTemplateId;
export type GetDetailsResult = EmailTemplateGeneral;
export type GetIsUsedParams = WithReleaseOrDraftProcessId & WithEmailTemplateId;
export type GetIsUsedResult = IsUsedInfo;

const EmailTemplates = {
  getList: apiReqSagaCreator<GetListParams, GetListResult>(
    API_REQ_ACTION_TYPES.PRINT_RENDER.EMAIL_TEMPLATES.GET_LIST,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId.emailTemplates._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          params[ApiUrlParams.releaseOrDraftProcessId],
      }),
    })
  ),
  create: apiReqSagaCreator<CreateParams, CreateResult>(
    API_REQ_ACTION_TYPES.PRINT_RENDER.EMAIL_TEMPLATES.CREATE,
    (args) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.processes.byId.emailTemplates._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
      }),
      data: excludeObjectProperty(args, ApiUrlParams.releaseOrDraftProcessId),
    })
  ),
  update: apiReqSagaCreator<UpdateParams, UpdateResult>(
    API_REQ_ACTION_TYPES.PRINT_RENDER.EMAIL_TEMPLATES.UPDATE,
    (args) => ({
      method: HTTP_METHODS.PUT,
      url: ApiPaths.processes.byId.emailTemplates.byId._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
        [ApiUrlParams.emailTemplateId]: args[ApiUrlParams.emailTemplateId],
      }),
      data: excludeObjectProperty(
        args,
        ApiUrlParams.releaseOrDraftProcessId,
        ApiUrlParams.emailTemplateId
      ),
    })
  ),
  delete: apiReqSagaCreator<DeleteParams, DeleteResult>(
    API_REQ_ACTION_TYPES.PRINT_RENDER.EMAIL_TEMPLATES.DELETE,
    (params) => ({
      method: HTTP_METHODS.DELETE,
      url: ApiPaths.processes.byId.emailTemplates.byId._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          params[ApiUrlParams.releaseOrDraftProcessId],
        [ApiUrlParams.emailTemplateId]: params[ApiUrlParams.emailTemplateId],
      }),
    })
  ),
  getDetails: apiReqSagaCreator<GetDetailsParams, GetDetailsResult>(
    API_REQ_ACTION_TYPES.PRINT_RENDER.EMAIL_TEMPLATES.DETAILS,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId.emailTemplates.byId._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          params[ApiUrlParams.releaseOrDraftProcessId],
        [ApiUrlParams.emailTemplateId]: params[ApiUrlParams.emailTemplateId],
      }),
    })
  ),
  getIsUsed: apiReqSagaCreator<GetIsUsedParams, GetIsUsedResult>(
    API_REQ_ACTION_TYPES.PRINT_RENDER.EMAIL_TEMPLATES.GET_IS_USED,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId.emailTemplates.byId.isUsed._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          params[ApiUrlParams.releaseOrDraftProcessId],
        [ApiUrlParams.emailTemplateId]: params[ApiUrlParams.emailTemplateId],
      }),
    })
  ),
};

export default EmailTemplates;
