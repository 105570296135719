import {
  TABLE_MENU_ACCESSOR,
  TEXTS,
} from 'pages/processDefinitionDataModelFields/config';
import { Action } from 'redux';
import { put } from 'redux-saga/effects';

import createATA from 'utils/createAddToasterAction';

interface TaskAction extends Action, TaskEvent {
  type: string;
}

interface TaskEvent {
  meta: {
    input: {
      gridStatusUpdate?: string;
    };
  };
}

function* dataModelFieldUpdateSuccessAction({ meta: { input } }: TaskAction) {
  if (input.gridStatusUpdate === TABLE_MENU_ACCESSOR.SHOW_FIELD_IN_GRID) {
    yield put(
      createATA({
        message: TEXTS.SHOW_IN_GRID_SUCCESS_TOAST,
      })
    );
  }

  if (input.gridStatusUpdate === TABLE_MENU_ACCESSOR.HIDE_FIELD_FROM_GRID) {
    yield put(
      createATA({
        message: TEXTS.REMOVE_FROM_GRID_SUCCESS_TOAST,
      })
    );
  }
}

export default dataModelFieldUpdateSuccessAction;
