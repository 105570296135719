import { useMemo } from 'react';

import { IServiceFactory } from './serviceFactoryInterface';
import { IBareClientRepo } from './services/bareClient/IBareClientRepo';
import { IExternalFormsRepo } from './services/externalForms/IExternalForms';
import { IFieldAliasesRepo } from './services/fieldAliases/IFieldAliases';
import { IFreshdeskTokenRepo } from './services/freshdeskToken/IFreshdeskTokenRepo';
import { IGridRepo } from './services/grid/IGridRepo';
import { IIndexingDocumentsRepo } from './services/indexingDocuments/IIndexingDocumentsRepo';
import { IIndexingSourcesRepo } from './services/indexingSources/IIndexingSourcesRepo';
import { IManagementEmailRepo } from './services/managementEmail/IManagementEmailRepo';
import { IManagementLogsRepo } from './services/managementLogs/IManagementLogsRepo';
import { IPrintRenderLogRepo } from './services/printRenderLogs/IPrintRenderRepo';
import { IProcessRepo } from './services/process/IProcessRepo';
import { IProcessInstanceRepo } from './services/ProcessInstance/IProcessInstanceRepo';
import { IProcessInstanceRepoV2 } from './services/ProcessInstanceV2/IProcessInstanceRepo';
import { IScanRepo } from './services/Scan/IScanRepo';
import { IScanScenarioRepo } from './services/ScanScenario/IScanScenarioRepo';
import { ISecurityRepo } from './services/security/ISecurityRepo';
import { ISystemLogsRepo } from './services/systemLogs/ISystemLogsRepo';
import { IUserRepo } from './services/users/IUserRepo';

export class ServiceFactoryMemo implements IServiceFactory {
  constructor(private implementation: IServiceFactory) {}

  getScanRepo = (): IScanRepo =>
    useMemo(() => this.implementation.getScanRepo(), []);

  getPrintRenderRepo = (): IPrintRenderLogRepo =>
    useMemo(() => this.implementation.getPrintRenderRepo(), []);

  getIndexingSourceRepo = (): IIndexingSourcesRepo =>
    useMemo(() => this.implementation.getIndexingSourceRepo(), []);

  getIndexingDocumentsRepo = (): IIndexingDocumentsRepo =>
    useMemo(() => this.implementation.getIndexingDocumentsRepo(), []);

  getProcessInstanceRepo = (): IProcessInstanceRepo =>
    useMemo(() => this.implementation.getProcessInstanceRepo(), []);

  getScanScenarioRepo = (): IScanScenarioRepo =>
    useMemo(() => this.implementation.getScanScenarioRepo(), []);

  getFreshdeskTokenRepo = (): IFreshdeskTokenRepo =>
    useMemo(() => this.implementation.getFreshdeskTokenRepo(), []);

  getSystemLogsRepo = (): ISystemLogsRepo =>
    useMemo(() => this.implementation.getSystemLogsRepo(), []);

  getManagementLogsRepo = (): IManagementLogsRepo =>
    useMemo(() => this.implementation.getManagementLogsRepo(), []);

  getUserRepo = (): IUserRepo =>
    useMemo(() => this.implementation.getUserRepo(), []);

  getManagementEmailRepo = (): IManagementEmailRepo =>
    useMemo(() => this.implementation.getManagementEmailRepo(), []);

  getProcessRepo = (): IProcessRepo =>
    useMemo(() => this.implementation.getProcessRepo(), []);

  getBareClientRepo = (): IBareClientRepo =>
    useMemo(() => this.implementation.getBareClientRepo(), []);

  getFieldAliasRepo = (): IFieldAliasesRepo =>
    useMemo(() => this.implementation.getFieldAliasRepo(), []);

  getProcessInstanceRepoV2 = (): IProcessInstanceRepoV2 =>
    useMemo(() => this.implementation.getProcessInstanceRepoV2(), []);

  getGridRepo = (): IGridRepo =>
    useMemo(() => this.implementation.getGridRepo(), []);

  getExternalFormsRepo = (): IExternalFormsRepo =>
    useMemo(() => this.implementation.getExternalFormsRepo(), []);

  getSecurityRepo = (): ISecurityRepo =>
    useMemo(() => this.implementation.getSecurityRepo(), []);
}
