import { getFormValues } from 'redux-form';

import subSelectorCreator from 'utils/subSelectorCreator';

import { FormsState } from './reducer';

const createFieldSelector = subSelectorCreator<FormsState>(
  (state) => state.processForms
);

export const formValuesSelector = (fromName: string) => getFormValues(fromName);

const FormsSelectors = {
  getList: createFieldSelector(({ list }) => list),
  getTotal: createFieldSelector(({ total }) => total),
  getDetails: createFieldSelector(({ details }) => details),
  getInitialForm: createFieldSelector(({ initialForm }) => initialForm),
  getObligationFields: createFieldSelector(
    ({ obligationFields }) => obligationFields
  ),
  getObligationFieldById: (id: string) =>
    createFieldSelector(({ obligationFields }) =>
      obligationFields?.find((e) => e.id === id)
    ),
};

export default FormsSelectors;
