import ROUTES from 'app/routes/paths';
import {
  UiWithDefinitionPreviewParams,
  UiWithDraftProcessId,
  UiWithReleaseOrDraftProcessId,
} from 'app/routes/uiPathParams';

import UiUrlParams from 'constants/uiUrlParams';

type PathCreator<T> = (params: UiWithDefinitionPreviewParams & T) => string;

export interface UrlCreator<T> {
  _: PathCreator<T>;
}

export enum DefinitionEntity {
  fields = 'fields',
  versions = 'versions',
  forms = 'forms',
  security = 'security',
  emailTemplates = 'emailTemplates',
  indexingSources = 'indexingSources',
}

export enum DefinitionVersionEntity {
  fields = 'fields',
  forms = 'forms',
  security = 'security',
  emailTemplates = 'emailTemplates',
  indexingSources = 'indexingSources',
  scanning = 'scanning',
}

const getRouteByVersion = <T = {}>({
  ids,
  entity,
  urlCreator,
}: {
  ids: UiWithDefinitionPreviewParams & T;
  entity?: DefinitionEntity | DefinitionVersionEntity;
  urlCreator?: UrlCreator<T>;
}) => {
  if (urlCreator) {
    return urlCreator._(ids);
  }
  return ids[UiUrlParams.processVersionId]
    ? ROUTES.process.definition.details.version[
        entity as DefinitionVersionEntity
      ](ids)
    : ROUTES.process.definition.details[entity as DefinitionEntity](
        ids as UiWithDefinitionPreviewParams &
          UiWithReleaseOrDraftProcessId &
          UiWithDraftProcessId
      );
};

export { getRouteByVersion };
