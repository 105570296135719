import cn from 'classnames';
import { ListenerEvent } from 'hooks/useClickOutside';
import React, { FC, memo, useMemo } from 'react';

import { ReactComponent as ArrowDisabled } from 'assets/icon/arrow-black-down-grey.svg';
import { ReactComponent as Arrow } from 'assets/icon/arrow-black-down.svg';

import Input from 'components/atoms/input';
import Text from 'components/atoms/text';
import DropDownList from 'components/molecules/dropDownList';

import { HIDE_FROM_FOCUS } from 'constants/global';

import { TEXTS } from './config';
import sm from './styles.module.scss';
import useSelect from './useSelect';

export type OnSelectChange = (value: SelectItem | string) => void;

export interface Props {
  isDisabled?: boolean;
  onClick?: () => void;
  onListToggle?: (isListOpen: boolean) => void;
  list?: SelectItem[];
  isReadOnly?: boolean;
  onSelectChange?: OnSelectChange;
  onSelectBlur?: (e: ListenerEvent, selected?: SelectItem | string) => void;
  selected?: SelectItem | string;
  withAutoFocus?: boolean;
  withSmallerIndent?: boolean;
  placeholder?: string;
  renderPostIcon?: (e: SelectItem | string) => Nullable<JSX.Element>;
  withoutBorder?: boolean;
  withLocalSearch?: boolean;
  isBottomOfPage?: boolean;
}

export const Select: FC<Props> = ({
  isDisabled,
  onClick,
  onSelectChange,
  onSelectBlur,
  onListToggle,
  list,
  selected,
  isReadOnly,
  withAutoFocus,
  withSmallerIndent,
  renderPostIcon,
  withLocalSearch = true,
  isBottomOfPage = false,
  ...rest
}) => {
  const {
    handleSearchChange,
    clickHandler,
    changeHandler,
    localList,
    wrapperRef,
    searchValue,
    isOpen,
    labelToRender,
  } = useSelect(
    useMemo(
      () => ({
        list,
        selected,
        isReadOnly,
        onClick,
        onSelectBlur,
        onListToggle,
        onSelectChange,
        isDisabled,
      }),
      [
        isDisabled,
        isReadOnly,
        list,
        onClick,
        onListToggle,
        onSelectBlur,
        onSelectChange,
        selected,
      ]
    )
  );

  // region ********************************JSX*********************************
  return (
    <div
      ref={wrapperRef}
      className={cn([sm.SelectWrapper], {
        [sm.SelectWrapper__disabled]: isDisabled,
        [sm.SelectWrapper__ReadOnly]: isReadOnly,
      })}
    >
      <Input
        {...rest}
        readOnly={!withLocalSearch}
        tabIndex={!withLocalSearch ? HIDE_FROM_FOCUS : undefined}
        onClick={!isOpen ? clickHandler : undefined}
        autoFocus={withLocalSearch && withAutoFocus}
        onChange={handleSearchChange}
        value={labelToRender}
        isDisabled={isDisabled}
        postIcon={
          !isReadOnly && (
            <button
              type="button"
              onClick={clickHandler}
              className={cn(sm.Arrow, {
                [sm.Arrow_Active]: isOpen,
              })}
            >
              {isDisabled ? <ArrowDisabled /> : <Arrow />}
            </button>
          )
        }
      />

      {isOpen && (
        <div
          className={cn(sm.SelectWrapper_List, {
            [sm.BottomOfPage]: isBottomOfPage,
          })}
        >
          {!!localList.length && (
            <DropDownList
              items={localList}
              selectedValue={selected}
              onClick={changeHandler}
              withSmallerIndent={withSmallerIndent}
              renderPostIcon={renderPostIcon}
            />
          )}
          {!localList.length && !!searchValue && (
            <div className={sm.NoSearchResults}>
              <Text content={TEXTS.NO_SEARCH_RESULTS} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
// endregion

export default memo(Select);
