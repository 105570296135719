import {
  ApiResponseAsync,
  translateAxiosErrorToApiError,
} from 'container/models';

import { authHeaderName } from 'api/apiReqSagaCreator/config';
import { handleExpiredTokens } from 'api/apiReqSagaCreator/httpRequest';
import { OdataURLBuilder } from 'api/types/OdataURLBuilder';

import BaseRepo from '../../BaseRepo';
import { ApplicationLogDTO, IManagementLogsRepo } from './IManagementLogsRepo';

export class ManagementLogsRepo
  extends BaseRepo
  implements IManagementLogsRepo
{
  getApplicationLogs = (
    request: OdataURLBuilder
  ): ApiResponseAsync<ApplicationLogDTO[] | number> =>
    handleExpiredTokens(() =>
      this.client.get<ApplicationLogDTO[] | number>(request.toString(), {
        headers: {
          [authHeaderName]: this.tokenAccessor(),
        },
      })
    )
      .then((response) => {
        const data = response.data;

        if (typeof data === 'number') return data;

        for (const value of Object.values(data)) {
          if ('actionDate' in value) {
            value.actionDate = new Date(value.actionDate);
          }
        }

        return data;
      })
      .catch(translateAxiosErrorToApiError);
}
