import { ApiError, isApiError } from 'container/models';

const ORIGINAL_ERROR_KEY = 'originalError';

/**
 * ApplicationError is a custom error type that extends Error to allow it to include user-friendly error messages.
 */
export class ApplicationError extends Error {
  public userMessage: string;
  public details?: any;
  public showToDeveloper: boolean;

  constructor(
    /**
     * A message that will be shown to the user, most likely in an error toast.
     * Keep it short and to the point. "{Action} failed." is good. Only explain
     * *why* if there's something the user can do about it. If you think it's a
     * transient error, something that will probably work if they try again, tell
     * them that.
     */
    userMessage: string,
    /**
     * A message that will be shown to the developer. This should be as descriptive
     * as you want. Stacktraces will be available by virtue of this being an Error
     * subclass. Additional detail (like variables, etc) can be added to the details
     * property in the options.
     */
    message: string,
    /**
     * Additional options for the error.
     *
     * showToDeveloper: Log this error? If false, error-handling code will ignore
     * this error when processing it for developer consumption. Defaults true.
     *
     * details: Any extra data that might be useful. This object will get deep-cloned,
     * so data passed here will be preserved exactly as it was when passed in.
     */
    options?: {
      showToDeveloper?: boolean;
      details?: any;
    }
  ) {
    super(message);
    this.userMessage = userMessage;
    this.details = attemptStructuredClone(options?.details ?? {});
    this.showToDeveloper =
      options?.showToDeveloper === undefined ? true : options.showToDeveloper;
  }

  /**
   * Creates an ApplicationError from any kind of error object. Makes an effort
   * to preserve as much information as possible.
   *
   * Has specific cases for handling when `err` is:
   * - ApplicationError: just returns the original error
   * - Error: creates a new ApplicationError and copies all properties from the original
   * - ApiError: creates a new ApplicationError
   *
   * Everything else gets stored in the details property.
   */
  public static FromError(
    /** An object to convert into an ApplicationError. */
    err: ApplicationError | Error | ApiError | any | null,
    /**
     * A message about this error to show to the user. Refer to `userMessage`
     * on the {@link ApplicationError} constructor for more details.
     */
    userMessage: string
  ): ApplicationError {
    // if it's already an ApplicationError, just return it
    if (isApplicationError(err)) return err;

    // if it's an Error, create a new ApplicationError from it, copying all properties from the original and storing the original as the detail
    if (err instanceof Error) {
      let newErr = new ApplicationError(userMessage, err.message);
      Object.assign(newErr, err);
      newErr.details[ORIGINAL_ERROR_KEY] = attemptStructuredClone(err);
      return newErr;
    }

    // if it's an ApiError, we can extract specific values from it
    // @TODO - if single error in array, take userMessage from that. If multiple, store all but take userMessage from param instead.
    if (isApiError(err)) {
      const msgError = err.data[0];
      if (msgError && !err.clientErrorString)
        return new ApplicationError(
          msgError.description,
          msgError.description,
          {
            details: err,
          }
        );

      return new ApplicationError(
        userMessage,
        `unknown API error: ${err.statusText}`,
        {
          details: err,
        }
      );
    }

    // if it's an object, create a new ApplicationError from it, storing the original object as the details.
    if (typeof err === 'object' && err !== null) {
      return new ApplicationError(userMessage, err.message ?? userMessage, {
        details: err,
      });
    }

    // otherwise, stringify it and return it
    return new ApplicationError(userMessage, String(err), { details: err });
  }

  public print() {
    console.groupCollapsed(
      `%cApplicationError: ${this.message} (${this.userMessage})`,
      'background-color: maroon; color: white;'
    );

    if ('details' in this) {
      console.group('Context');

      const { [ORIGINAL_ERROR_KEY]: originalError, ...rest } = this.details;

      if (originalError) console.error('Original error:\n', originalError);

      if (Object.keys(rest).length > 0) console.debug('Other details:', rest);

      console.groupEnd();
    }

    console.error(this.stack);

    console.groupEnd();
  }
}

function attemptStructuredClone(obj: any): any {
  try {
    return structuredClone(obj);
  } catch (e) {
    return obj;
  }
}

export function isApplicationError(obj: any): obj is ApplicationError {
  return (
    obj &&
    typeof obj === 'object' &&
    (obj instanceof ApplicationError || 'userMessage' in obj)
  );
}
