import { combineReducers } from 'redux';

import { GridColumn } from 'components/organisms/grid/types';

import { API_REQ_ACTION_TYPES } from 'api/actions';
import { GetListResult, GridTemplateGeneral } from 'api/endpoints/grid';

import { SystemStatus } from 'constants/global';

import { GRID_ACTION_TYPES } from './actions';

export interface GridState {
  templates: Nullable<GridTemplateGeneral[]>;
  activeTemplate: Nullable<GridTemplateGeneral>;
  selectedColumns: GridColumn[];
  links: Hypermedia;
}

const grid = combineReducers<GridState>({
  templates(state = null, { type, payload }) {
    switch (type) {
      case API_REQ_ACTION_TYPES.GRID.GET_ALL_TEMPLATES.SUCCESS:
        return payload ? payload.data : null;
      default:
        return state;
    }
  },
  links(state = {}, { type, payload }) {
    switch (type) {
      case API_REQ_ACTION_TYPES.GRID.GET_ALL_TEMPLATES.SUCCESS:
        return payload?.links ? payload.links : {};
      default:
        return state;
    }
  },
  activeTemplate(
    state = null,
    {
      type,
      payload,
    }: {
      type: string;
      payload?: GetListResult | GridTemplateGeneral;
    }
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.GRID.GET_ALL_TEMPLATES.SUCCESS:
        if (Array.isArray((payload as GetListResult)?.data)) {
          return (
            (((payload as GetListResult)?.data as GetListResult['data'])?.find(
              (e) => e.status === SystemStatus.Active
            ) as GridTemplateGeneral) ?? null
          );
        }
        return null;
      case GRID_ACTION_TYPES.CLEAR_ACTIVE_TEMPLATE:
        return null;
      case API_REQ_ACTION_TYPES.GRID.ACTIVATE_TEMPLATE.SUCCESS:
      case API_REQ_ACTION_TYPES.GRID.SAVE_TEMPLATE.SUCCESS:
        return payload as GridTemplateGeneral;
      default:
        return state;
    }
  },
  selectedColumns(state = [], { type, payload }: ReduxAction<GridColumn[]>) {
    switch (type) {
      case GRID_ACTION_TYPES.SET_SELECTED_COLUMNS:
        return payload || null;

      default:
        return state;
    }
  },
});

export default grid;
