import { InstanceCountData } from 'api/endpoints/processInstance';

import { DataModelFieldSubType, DataModelFieldType } from 'constants/global';

import { string } from '.';

const TEXT_REG_EXP = /[a-zA-Z\d\s!#$%&'"*+-/=?^_`{|}~.,;:[\]]/;

const getOnlyText = (str?: string) =>
  str
    ? str
        .split('')
        .filter((char) => TEXT_REG_EXP.test(char))
        .join('')
    : '';

const getOnlyNumbers = (str?: string | number) => {
  if (typeof str === 'number') return str;
  if (!str) return '';
  const replaced = str.replace(/[^\d]/g, '');
  return replaced.length ? +replaced : '';
};

const cutStrSpaces = (value: string) => value.replace(/\s/g, '');

const onlyTextAllow = (value: string) => value.replace(/[^A-Za-z]/g, '');

const getCountByType = (
  type: keyof InstanceCountData,
  count?: Nullable<InstanceCountData>
) => {
  if (count && count[type] > 0) {
    return `(${count[type]})`;
  }
  return '';
};

// eslint-disable-next-line
const normalizePhoneField = (phone: string) => phone.replace(/[\+\-]/g, '');

// eslint-disable-next-line
const normalizeDate = (date: string) => date.replace(/[\/\.]/g, '');

const PHONE_FIELD_FULL_LENGTH = 11;

const normalizeRevertedDate = (date: string, type: string) => {
  const normalizedValue = normalizeDate(date);
  if (type === DataModelFieldSubType.DateReverse) {
    const [d = '', dd = '', m = '', mm = '', ...year] =
      normalizedValue.split('');
    return `${m}${mm}${d}${dd}${(year || []).join('')}`;
  }
  return normalizedValue;
};

const normalizeInitialField = (value: unknown, type?: string) => {
  if (value === null || typeof value === 'undefined') return value;

  let normalizedValue = value;

  switch (type) {
    case DataModelFieldSubType.NumberPhone:
    case DataModelFieldSubType.TextPhone:
      normalizedValue =
        type === DataModelFieldSubType.NumberPhone && !value
          ? ''
          : string.normalizePhoneField(String(value));
      if (
        (normalizedValue as string).length === PHONE_FIELD_FULL_LENGTH &&
        (normalizedValue as string).startsWith('1')
      ) {
        normalizedValue = String(value).substr(1);
      }
      break;

    case DataModelFieldType.Date:
    case DataModelFieldSubType.DateDefault:
    case DataModelFieldSubType.DateReverse:
    case DataModelFieldSubType.DateDots:
      break;

    case DataModelFieldType.Number:
      normalizedValue = getOnlyNumbers(value as string | number);
      break;
  }

  return normalizedValue;
};

const normalizeSubmittedField = (value: unknown, type?: string) => {
  if (value === null || typeof value === 'undefined') return value;

  let normalizedValue = value;

  switch (type) {
    case DataModelFieldSubType.NumberPhone:
      normalizedValue = Number(normalizePhoneField(String(value)));
      break;

    case DataModelFieldSubType.TextPhone:
      normalizedValue = normalizePhoneField(String(value));
      break;

    case DataModelFieldSubType.DateDefault:
    case DataModelFieldSubType.DateReverse:
    case DataModelFieldSubType.DateDots:
    case DataModelFieldType.Date: {
      normalizedValue = normalizeRevertedDate(String(value), type);
      break;
    }

    case DataModelFieldType.Select:
      normalizedValue =
        typeof value === 'string' ? value : (value as { value: string }).value;
      break;

    case DataModelFieldType.Number:
      normalizedValue = Number(value);
      break;
  }
  return normalizedValue;
};

const camelize = (str: string) =>
  str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

export {
  getOnlyText,
  getOnlyNumbers,
  cutStrSpaces,
  getCountByType,
  onlyTextAllow,
  normalizePhoneField,
  normalizeDate,
  normalizeSubmittedField,
  normalizeInitialField,
  camelize,
};
