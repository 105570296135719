import { FormGeneral } from 'api/endpoints/processForm';
import { WithExternalFormId } from 'api/types/pathParams';

import ApiUrlParams from 'constants/apiUrlParams';

import { API_REQ_ACTION_TYPES } from '../actions';
import apiReqSagaCreator from '../apiReqSagaCreator';
import { ApiPaths, CONTENT_TYPE, HTTP_METHODS } from '../constants';

const { REACT_APP_API_DOCUMENT_SERVICE: documentServiceUrl } = process.env;

export interface ValidateParams {
  ott: string;
}

export interface SubmitParams {
  params: DynamicFormNormalizedParams;
  token: string;
}

export interface UploadFileData {
  field: FormData;
}

export interface ValidateResult extends Omit<BaseEntity, 'name'> {
  expiration?: number;
  form: FormGeneral;
  params: KeyValuePairs;
}

export type SubmitResult = unknown;
export type ResendParams = WithExternalFormId;
export type ResendResult = unknown;
export type UploadFileParams = WithExternalFormId & UploadFileData;
export type UploadFileResult = { url: string };

const External = {
  form: {
    submit: apiReqSagaCreator<SubmitParams, SubmitResult>(
      API_REQ_ACTION_TYPES.EXTERNAL.FORMS.SUBMIT,
      (data) => ({
        method: HTTP_METHODS.POST,
        url: ApiPaths.external.form.submit._(),
        data,
      })
    ),
    resend: apiReqSagaCreator<ResendParams, ResendResult>(
      API_REQ_ACTION_TYPES.EXTERNAL.FORMS.RESEND,
      (params) => ({
        method: HTTP_METHODS.POST,
        url: ApiPaths.external.form.byId.resend._({
          [ApiUrlParams.externalFormId]: params[ApiUrlParams.externalFormId],
        }),
      })
    ),
  },
  uploadFile: apiReqSagaCreator<UploadFileParams, UploadFileResult>(
    API_REQ_ACTION_TYPES.EXTERNAL.UPLOAD,
    (args) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.external.form.byId._({
        [ApiUrlParams.externalFormId]: args[ApiUrlParams.externalFormId],
      }),
      data: args.field,
      headers: {
        'Content-Type': CONTENT_TYPE.MULTIPART_FORMDATA,
      },
      baseURL: documentServiceUrl,
    })
  ),
};

export default External;
